.CheckLanguage{
    display: flex;
    grid-column: 3;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    cursor: pointer;
}

.CheckLanguageImg{
    margin-top: -3px;
    padding-left: 10px;
    padding-right: 5px;
}

.LangActive>img{
    margin-left: 10px;
}
.LangNoActive>img{
    display: none;
}

.CheckLanguageImg>img {
    /*border-radius: 9px;*/
}

.CheckLanguageOut>a:first-of-type{
    padding-top: 17px;
}
.CheckLanguageOut>a:last-of-type{
    padding-bottom: 17px;
}

.CheckLanguageOut>a{
    padding-top: 7px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 800px) {
    .CheckLanguageOutOut>.CheckLanguageOut>a>.CheckLanguage>.CheckLanguageTitle{
        display: block;
    }
}

.CheckLanguageOut{
    /*width: 123px;*/
    /*height: 137px;*/
    background-color: white;
    box-shadow: 0 4px 10px 0 rgba(0, 51, 100, 0.25);
    border-radius: 3px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 500;
    min-width: 112px;
}

.CheckLanguageOutOut{
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: -1px;
    grid-row: 1;
    grid-column: 5;
}

.CheckLanguageOutOut>.CheckLanguageNo{
    display: none;
}
.CheckLanguageNo{
    display: none;
}

.CheckLanguageTitle{
    text-decoration: none;
    font-size: 10px;
}


@media only screen and (max-width: 1000px) {
    .CheckLanguageOutOut{
        grid-row: 1;
        grid-column: 2;
    }
}
@media only screen and (max-width: 800px) {
    .CheckLanguageImg{
        display: none;
    }
    .CheckLanguageTitle{
        font-size: 14px;
        text-decoration: underline;
    }
    .CheckLanguageOutOut{
        margin-right: 30px;
    }
    }
@media only screen and (max-width: 600px) {
    .CheckLanguage{
        padding-left: 0;
        padding-right: 0;
    }
}
