.menu_header_small {
    display: none;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #075a89;
align-items: center;
    justify-content: center;
    margin-top: 6px;
}

@media only screen and (max-width: 800px) {
    .menu_header_small{display : flex;grid-column: 5; grid-row: 1;
        align-self: center;
    }}


.menuSmallCross{
    position: absolute;
    left: -35px;
    top: 12px;
    z-index: 600;
    cursor: pointer;
}

.phoneMenu{
    width: 255px;
    position: absolute;
    right: 0;
    top: 0;
    border: 1px black solid;
    background-color: white;
    z-index: 500;
}



.phoneMenuTopButtons{
    display: flex;
    justify-content: space-around;
    background-color: rgb(242,242,242);
    width: 100%;
    height: 49px;
}

.phoneMenuTopButtonsElement{
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    color: rgb(42,51,60);
    line-height: 47px;
}

.phoneMenuTopButtonsElement>img{
    position: relative;
    top: 3px;
    margin-right: 6px;

}

.phoneMenuMainElementSubMenu{
    display: none;
}

.phoneMenuMainElement>.phoneMenuMainElementTitle{
    font-family: GothamPro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: rgb(10,121,191);
    line-height: 50px;
    padding-left: 22px;
}

.phoneMenuMainElementActive>.phoneMenuMainElementTitle{
    font-family: GothamPro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: rgb(28,37,47);
    line-height: 50px;
    padding-left: 22px;
}

.phoneMenuMainElementActive>.phoneMenuMainElementSubMenu{
    display: inline-flex;
    flex-direction: column;
}

.phoneMenuMainElementSubMenuElement {
    font-family: GothamPro, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    color: rgb(10, 121, 191);
    line-height: 35px;
    display: inline-flex;
    padding-right: 15px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.phoneMenuMainElementSubMenuElement>a{
    padding-left: 22px;
    color: rgb(10, 121, 191);
}

.phoneMenuMainElementSubMenuElement>.phoneMenuMainElementSubMenuElementActive{
    color: white;
    background-color: rgb(10, 121, 191);
}


@media only screen and (max-width: 600px) {
    .CabinetHeader{
        grid-column: 3;
    }
    .menu_header_small{
        grid-column: 4;
    }
}