.ContestIndex{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.ContestNewbieCurrentGrid{
    display: grid;
    grid-template-columns: auto 180px;
    grid-column-gap: 5px;
}

.ContestIndexTitle{
    font-size: 24px;
    padding-top: 28px;
    color: #075a89;
    padding-left: 20px;
    margin-bottom: 50px;
}

.ContestIndexGrid{
    display: grid;
    margin-bottom: 60px;
    grid-template-columns: auto 180px;
    grid-column-gap: 50px;
}

.ContestIndexGridMain{
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 40px;
    justify-content: center;
}

.ContestIndexGridMain>a>div{
    margin-right: 20px;
    margin-left: 20px;
}

.ContestIndexGridMainElement{
    width: 324px;
    height: 181px;
    color: white;
    font-size: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ContestIndexGridMainElement:hover{
    box-shadow: inset 0 0 27px -3px rgba(255,255,255,0.8);
}


.ContestIndexGridMainElement01{
    background-image: url("../../../static/img/Contest/champions.jpg");
}
.ContestIndexGridMainElement02{
    background-image: url("../../../static/img/Contest/kings.jpg");
}
.ContestIndexGridMainElement03{
    background-image: url("../../../static/img/Contest/prize.jpg");
}

.ContestIndexGridMain>a{
    text-decoration: none;
}
.ContestIndexGridMainElementIn{
    padding: 40px;
    display: flex;
    align-items: center;
}

.ContestIndexGridMainElementText{
    margin-left: 30px;
}
@media only screen and (max-width: 1060px) {
    .ContestIndexGrid>.ContestMenu{
        display: none;
    }
    .ContestIndexGrid{
        display: block;
    }
}

@media only screen and (max-width: 800px) {
    .ContestIndexGridMain{
        display: flex;
        flex-wrap: wrap;

    }
}

/*@media only screen and (max-width: 370px) {*/
/*    .ContestIndexGridMainElement{*/
/*        width: 100%;*/
/*        padding: 0;*/
/*    }*/
/*    .ContestIndexGridMainElementIn{*/
/*        padding: 0;*/
/*    }*/
/*}*/


