.MainMenuTop {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    font-family: GothamPro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    max-width: 1000px;
    margin: auto;
    color: #858484;
    background-color: #f2f2f2;
    height: 47px;
}

.MainMenuTop>div{
    width: 100%;
}


.MainMenuElement{
    width: 100%;
    text-align: center;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ElementMenuActive{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: gray;
    color: white;
}

.MainMenuElement:hover{
    /*background-color: #a6a7aa;*/
    background-color: #075a90;
    color: white;
    cursor: pointer;
}

.MainMenuElementActive>div{
    background-color: #075a89;
}
.MainSubMenuElement{
    width: 100%;
    text-align: center;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MainSubMenuElement:hover{
    /*background-color: #c8ddfd;*/
    /*background-color: #075a90;*/
    background-color: #156b9c;

}
.ElementMenuActive{
    background-color: #a6a7aa;
    color: white;
    cursor: pointer;
}

.MainMenuHeader{
    display: none;
    position: absolute;
    background-color: #484848;
    width: 100%;
    max-width: 1000px;
    justify-content: space-around;
    left: 80px;
}

.ElementMenuActive>.MainMenuHeader{
    display: flex;
}

.MainMenuSub>.MainMenuElementOutTraderNew:first-of-type>a>div{
    padding-left: 15px;
    margin-right: 15px;
    padding-right: 15px;
}
.MainMenuSub>.MainMenuElementOutTraderNew:nth-of-type(2)>a>div{
    margin-left: 20px;
    margin-right: 5px;
}
.MainMenuSub>.MainMenuElementOutTraderNew:nth-of-type(3)>a>div{
    margin-left: 20px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.MainMenuSub>.MainMenuElementOutTraderNew:nth-of-type(4)>a>div{
    margin-left: 30px;
    margin-right: 5px;
}
.MainMenuSub>.MainMenuElementOutTraderNew:nth-of-type(5)>a>div{
    margin-left: 30px;
    margin-right: 5px;
}
.MainMenuSub>.MainMenuElementOut:first-of-type:hover{
    /*background-color: #c8ddfd;*/
    background-color: #075a90;
}
.MainMenuSub{
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    background-color: #4b4e56;
    justify-content: space-around;
    color: white;
}

.MainMenuSubTerminal{
    background-color: #f0f5f8;
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    justify-content: space-around;
    color: #02518e;
    padding-top: 15px;
    padding-bottom: 15px;
}

.MainMenuSubTerminal>img{
    position: relative;
    top: 5px;
    left: 5px;
}

.MenuElementTerminalDiv{
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 10px;
}
.MenuElementTerminalDiv:hover{
    background-color: white;
    border-radius: 5px;
}

.MenuElementTerminalDiv>img{
    margin-right: 10px;
    position: relative;
    top: -3px;
}

.MainMenuElementOut{
    width: 100%;
}
.MainMenuElementOut>a{
    text-decoration: none;
}

.MenuElementTerminalDiv{
    display: flex;
}

.MenuElementTerminalDivImgActive{
    display: none;
}

.MainMenuElementActiveNow>.MenuElementTerminal>.MenuElementTerminalDiv>.MenuElementTerminalDivImgActive{
    display: block;

}
.MainMenuElementActiveNow>.MenuElementTerminal>.MenuElementTerminalDiv>.MenuElementTerminalDivImg{
    display: none;
}

.MainMenuElementActiveNow>.MenuElementTerminal>.MenuElementTerminalDiv{
    background-color: #cdd4da;
    border-radius: 5px;
}

.MenuElementTerminalDiv:hover>.MenuElementTerminalDivImgActive{
    display: block;
}
.MenuElementTerminalDiv:hover>.MenuElementTerminalDivImg{
    display: none;
}

@media only screen and (max-width: 920px) {
    .MenuElementTerminal>.MenuElementTerminalDiv{
        font-size: 12px;

    }
}
@media only screen and (max-width: 900px) {
    .MainMenuTop>div:first-of-type>div{
        padding-left: 15px;
    }
    .MainMenuTop>div:first-of-type>div>nobr{
        position: relative;
        left: -10px;
    }
    .MainMenuTop>div:nth-of-type(2)>div{
        margin-left: 15px;
    }
}
@media only screen and (max-width: 800px) {
    .MainMenu{
        display: none;
    }
}

