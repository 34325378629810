body{
    font-family: HelveticaNeueCyr, sans-serif;
    margin-left: 0;
    margin-right: 0;
}

div, input, button, textarea{
    outline:none;
}

.StandartText{
    font-size: 16px;
    line-height: 23px;
}
.StandartPage{
    margin: auto;
    max-width: 1000px;
}

a,a:visited{
    color: inherit;
}

.ButtonOut{
    display: flex;
    justify-content: center;
}

.isVisible{
    display: block;
}

.ProfiforexStandartText{
    line-height: 19px;
    font-size: 13px;
    color: #484848;
    margin-top: 13px;

}
.ProfiforexStandartH2{
    line-height: 1.4;
    font-size: 1.5em;
    color: #484848;
    margin-top: 16px;
}
.ProfiforexStandartH3{
    line-height: 1.4;
    font-size: 1.17em;
    color: #484848;
}

.StandartH2{
    font-size: 20px;
    color: #06466b;
    padding-bottom: 10px;
}
.StandartForHREF{
    color: #06466b;
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px;
}

.isNoVisible{
    display: none !important;
}

@media only screen and (max-width: 1020px) {
    .StandartIn{
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media only screen and (max-width: 350px) {
    .StandartIn{
        padding-left: 5px;
        padding-right: 5px;
    }
}
.StandartTitle{
    font-size: 36px;
    font-weight: 400;
    color: #075a89;
}

.StandartTitleOut{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.StandartTitleOutTerminal{
    width: 100%;
}

.StandartTitleTop>div{
    margin-top: 30px;
}
.StandartTitleTop>div:first-of-type{
    padding-right: 15px;
}

@media only screen and (min-width: 500px) {
    .StandartPhone {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .StandartNoPhone {
        display: none;
    }
}


.StandartAllBlack500{
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 500;
}

.Button3D{
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 42px;
    width: auto;
    border-radius: 5px;
    background-color: #fcb23c;
    color: white;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 5;
}

.Button3DOut>div>div>img{
    position: relative;
    top: 6px;
}

.Button3DUp{
    display: none;
    width: 100%;
    height: 7px;
    position: relative;
    top: 3px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #6096bd;
}

.Button3DBottom{
    width: 100%;
    height: 7px;
    position: relative;
    top: -3px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #6096bd;
}

.Button3DOut:active>div>.Button3DUp{
    display: block;
}
.Button3DOut:active>div>.Button3DBottom{
    display: none;
}
.Button3D:hover{
    background-color: #fcb84b;
    transition: all .2s linear;
}

.Button3D:active{
    color: white;
}

.ButtonYellow{
    font-weight: 500;
    font-size: 13px;
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 45px;
    width: auto;
    border-radius: 5px;
    background-color: #fcb23c;
    border: 2px solid #fcb23c;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    cursor: pointer;
}

.ButtonYellow:hover{
    background-color: #2f4563;
    border: 2px solid #2f4563;
    color: white;
    transition: all .2s linear;
}

.ButtonYellow:active{
    background-color:  #2f4563;
    border-color:  #2f4563;
    color: white;
}


.ButtonTerminalMobile{
    font-weight: 700;
    font-size: 16px;
    height: 49px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 48px;
    border-radius: 25px;
    border: solid 1px #ff9000;
    background: #ff9000;
    color: white;
    text-align: center;
}

.ButtonTerminalMobile:hover{
    background: none;
    border: 1px solid #eceae6;
    color: #0069d4;
}

.ButtonTerminalMobile:active{
    background-color:  #0a79bf;
    color: white;
}

.ButtonRed{
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 42px;
    width: auto;
    border-radius: 28px;
    background-color: #ea4f40;
    border: 2px solid #ea4f40;
    color: white;
    text-align: center;
    cursor: pointer;

}

.ButtonRed:hover{
    background-color: #2f4563;
    border: 2px solid #2f4563;
    color: white;
    transition: all .2s linear;
}

.ButtonRed:active{
    background-color:  #2f4563;
    border-color:  #2f4563;
    color: white;
}


.html404TopButton{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 20px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border:  2px solid #560d4b;
    background: none;
    color: #023776;
    text-align: center;
    margin-right: 26px;
}

.html404TopButton:hover{
    border-color:  #560d4b;
    color: #560d4b;
}

.html404TopButton:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
}