
@media only screen and (min-width: 600px) {
    .ContestResultsNoIMGOut>.ContestMenu>a>.ContestMenuElement{
        height: 120px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 30px;
        margin-right: 30px;
    }
    .ContestResultsNoIMGOut>.ContestMenu{
        display: flex;
        justify-content: center;
        width: 100%;
    }
}