.HeaderTop{
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto;
    margin-bottom: 5px;
}

.HeaderTopLogo{
    display: flex;
    align-items: flex-start;
    grid-column: 1;
    grid-row: 1/ span 2;
}

.HeaderLiveChat{
    background-image: url("../../../static/img/Header/liveChatDesktop.svg");
    background-repeat: no-repeat;
    width: 111px;
    height: 63px;
    color: white;
    text-decoration: underline;
    text-align: center;
    /*background-position-y: -10px;*/
    position: relative;
    top: -10px;
    margin: auto;
    grid-row: 2;
    grid-column: 3;
}

.HeaderLiveChatText{
    position: relative;
    top: 17px;
}
.HeaderThreeButtons{
    margin-top: 14px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    grid-column: 2/span 2;
    grid-row: 2;
}

.HeaderThreeButtons>div{
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -5px;
}

.HeaderThreeButtonsElement{
    color: #404040;
}
.HeaderThreeButtonsElement:hover{
    color: #2d4589;
}
.HeaderThreeButtonsElement:active{
    color: #20305f;
}

.HeaderPersonalArea{
    height: 19px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    position: relative;
    top: -2px;
}

.HeaderPersonalAreaIMG{
    width: 49px;
    height: 49px;
    background-color: #075a89;
    display: flex;
    justify-content: center;
    align-items: center;
}
.HeaderPersonalAreaText{
    /*height: 49px;*/
    height: 19px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #33363f;
    margin-left: 10px;
}
.HeaderPersonalAreaIMG:hover{
    /*width: 49px;*/
    /*height: 49px;*/
    background-color: #1f6a95;
}

.HeaderPersonalArea{
    display: flex;
    justify-content: space-around;
    align-content: center;
}

@media only screen and (max-width: 1000px) {
    .HeaderTop{
        grid-template-columns: 120px auto auto;
        grid-column-gap: 40px;
    }
    .HeaderLiveChat{
        margin: initial;
        background-image: url("../../../static/img/Header/liveChat.svg");
        position: relative;
        top: 6px;
    }
    .HeaderTopLogo{
        align-items: center;
        justify-content: flex-end;
    }
    .HeaderLiveChatText{
          top: 9px;
      }
}
@media only screen and (max-width: 800px) {

    .HeaderTopLogo{
        grid-row: 1;
        grid-column: 1;
    }
    .HeaderThreeButtons{
        grid-column: 1/span 5;
    }

    .HeaderLiveChat{
        top: 15px;

        grid-row: 1;
        grid-column: 3;
    }
    .HeaderTop{
        grid-column-gap: 0;
        justify-content: space-around;
        width: 100%;
    }
    .HeaderTop{
        grid-column-gap: 0;
    }

}


.HeaderThreeButtons>img{
    display: none;
}

    @media only screen and (max-width: 800px) {

        .HeaderThreeButtons>img{
            display: block;
        }
        .HeaderThreeButtons{
            background-color: #eeeeee;
            border: 1.5px solid #fc9a0f;
            border-radius: 5px;
            height: 50px;
            display: flex;
            /*align-items: center;*/
            justify-content: space-around;
            line-height: 60px;
        }
        .HeaderThreeButtonsElement>a{
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
        }
        .HeaderThreeButtons>img{
            margin-top: 6px;
        }
    }

.HeaderThreeButtonsElement{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .HeaderTop{
        grid-template-columns: auto auto auto auto;

    }

}
@media only screen and (max-width: 550px) {
    .HeaderThreeButtons{
        align-items: center;
    }
    .HeaderThreeButtons>div{
        align-items: center;
        top: 0;
    }
    .HeaderThreeButtonsElement{
        padding-left: 10px;
        padding-right: 10px;
        line-height: initial;
    }
    .HeaderLiveChat{
        margin-left: 5px;
    }
}

.CheckLanguageYes>a>.CheckLanguage>.CheckLanguageImg{
    display: block;
}

@media only screen and (max-width: 400px) {
    .HeaderLiveChat{
        width: 80px;
        margin-left: 5px;
    }
    .HeaderLiveChatText{
        top: 5px;
    }
}

/*@media only screen and (max-width: 800px) {*/
/*    .HeaderAll{*/
/*        display: none;*/
/*    }*/
/*}*/

@media only screen and (max-width: 600px) {
    .HeaderThreeButtons{
        grid-column: 1/span 4;
    }
}

.HeaderTwitter{
    grid-column: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: 12px;
    /*width: 45px;*/
}
@media only screen and (max-width: 1000px) {
    .HeaderTwitter{
        grid-row: 1;
        grid-column: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 85px;
        position: relative;
        top: -6px;
        /*width: 45px;*/
    }
}

