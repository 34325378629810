
.AllBlack{
    background-color : #000;
    height : 100%;
    left : 0;
    opacity : 0.50;
    position : fixed;
    top : 0;
    width : 100%;
    z-index : 100;
}

.StandartNotVisible{
    display: none;
}


