.TerminaliPhone{
    font-family: Geometria, sans-serif;
    max-width: 1000px;
    margin: auto;
    background-color: white;
}

.TerminaliPhoneiPhone{
    font-family: 'Geometria', sans-serif;
}

.TerminaliPhoneTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
}

.TerminaliPhone{
    font-family: Geometria, sans-serif;
}

.TerminaliPhoneInTitleGridButtons>a{
    width: 122px;
    margin-left: 22px;
}

.TerminaliPhoneInPart01{
    display: grid;
    grid-template-columns: 3fr 1fr auto;
    height: 638px;
    padding-top: 30px;
}

.TerminaliPhoneTitle{
    grid-column: 1/span 3;
    grid-row: 1 ;
}

.TerminaliPhoneInTitleGridButtons{
    grid-column: 3;
    grid-row: 1;
    display: flex;
    justify-content: space-around;
}


.TerminaliPhoneInPart01Left{
    grid-column: 1;
    grid-row: 3;
}

.TerminaliPhoneInPart01Right{
    grid-column: 2;
    grid-row: 2;
    left: -10px
}

@media only screen and (max-width: 1050px) {

    .TerminaliPhoneInTitleGridButtons>a{
        padding-bottom: 10px;
    }
    .TerminaliPhoneInPart01{
        height: 800px;
    }

    .TerminaliPhoneInPart01Right{
        top: -40px;
    }
}


.TerminaliPhoneInPartStore{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-column: 1 /span 3;
    grid-row: 2;

}

.TerminaliPhoneInPart01LeftStore>a>img{
    height: 50px;
}

.TerminaliPhoneInPart01LeftText{
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    color: #413422;
    padding-left: 26px;
}

.TerminaliPhoneInPart01Right{
    grid-column: 3;
    grid-row: 2 /span 3;
    position: relative;

}

@media only screen and (max-width: 1050px) {
    .TerminaliPhoneInPartStore{
        grid-column: 1 /span 3;
        grid-row: 2;
    }


}
@media only screen and (max-width: 950px) {
    .TerminaliPhoneInPart01Right {
        grid-column: 3;
        grid-row: 3;
        position: relative;

    }
}


.TerminaliPhoneInPart01LeftTextMain{
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    line-height: 30px;
}



.TerminaliPhoneInPart02{
    background-color: #f3f3ef;
    height: 538px;
    position: relative;
    top: -100px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 66px;}

.TerminaliPhoneInPart01RightMainImage{
    position: absolute;
    top: 60px;
    left: -10px;

}

.TerminaliPhoneInPart02Left>img{
    position: relative;
    top: -120px;
}

.TerminaliPhoneInPart02Right>ul,.TerminaliPhoneInPart03Left>ul{
    padding: 0;
}


.TerminaliPhoneInPart02Right>ul>li,.TerminaliPhoneInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminaliPhoneInPart02Right>ul>li:before,.TerminaliPhoneInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminaliPhoneInPart02Right>.TerminaliPhoneTitleH2,.TerminaliPhoneInPart03Left>.TerminaliPhoneTitleH2{
    padding: 0 30px 0 0;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

.TerminaliPhoneInPart03{
    margin-top: -60px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-template-rows: auto;
    position: relative;
    z-index: 10;
    background-color: white;
}


@media only screen and (max-width: 1050px) {
    .TerminaliPhoneInPart02{
        height: auto;
        padding-top: 35px;
    }
    .TerminaliPhoneInPart03{
        padding-top: 0;
    }
    .TerminaliPhoneInPart02Left>img{
        top: 0;
    }

}

.TerminaliPhoneInPart03Right{
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.TerminaliPhoneInPart03Right>img{
    height: auto;
}


@media only screen and (max-width: 1050px) {
    .TerminaliPhoneInPart02{
        height: auto;
    }
    .TerminaliPhoneInPart02Left>img{
        top: 0;
    }

}
@media only screen and (max-width: 900px) {
    .TerminaliPhoneInTitleGridButtons{
        justify-content: center;
    }
    .TerminaliPhoneInPart03{
        margin-top: -80px;}
    .TerminaliPhoneInPart01RightMainImage{
        display: none;
    }
    .TerminaliPhoneInPart01Right>div>.TerminalComponent3IMGCircles{
        margin-bottom: 20px;
    }

}



@media only screen and (max-width: 900px) {

    .TerminaliPhoneInPart01LeftTextMain{
        font-size: 18px;
    }
    .TerminaliPhoneiPhone>.TerminaliPhoneIn>.TerminaliPhoneInPart01>.TerminaliPhoneInPart01Right{
        grid-column: 1/span 3;
    }
    .TerminaliPhoneInTitleGridButtons{
        flex-direction: column;
        grid-row: 1 /span 2;
        justify-self: flex-end;
        align-self: flex-start;
    }
    .TerminaliPhoneInPart03Right{
        height: auto;
    }

}

.TerminaliPhoneInTitleGridButtons{
    height: auto;
}

.TerminaliPhoneInTitleGridButtons>a{
    height: 49px;
}

@media only screen and (max-width: 700px) {
    .TerminaliPhoneInPart02Right,.TerminaliPhoneInPart03Left,.TerminaliPhoneInPart01LeftTextMain{
        padding-right: 10px;
        padding-left: 10px;
    }
    .TerminaliPhoneInPartStore{
        justify-content: center;
    }

    .TerminaliPhoneInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .TerminaliPhoneTitle{
        grid-column: 1;
        grid-row: 1 ;
        text-align: center;
    }

    .TerminaliPhoneInPartStore{
        grid-column: 1;
        grid-row: 3;
    }

    .TerminaliPhoneInTitleGridButtons{
        flex-direction: row;
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
        margin-top: 10px;

    }

    .TerminaliPhoneInPart01RightMainImage{
        display: none;
    }
    .TerminaliPhoneComponent3IMGCircles{
        margin-top: 0 !important;
        margin-bottom: 30px !important;
    }


    .TerminaliPhoneInPart01Left{
        grid-column: 1;
        grid-row: 5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .TerminaliPhoneInPart01Right{
        top: 0;
        grid-column: 1;
        grid-row: 4;
        display: flex;
        justify-content: center;
    }

    .TerminaliPhoneInPart02{
        top: 20px;
        background-color: white;
        grid-template-columns: auto;
    }
    .TerminaliPhoneInPart02Left{
        margin: auto;
    }
    .TerminaliPhoneInPart03{
        grid-template-columns: auto;
    }
    .TerminaliPhoneInPart03Right{

        display: flex;
        justify-content: center;
    }


    .TerminaliPhoneInPart02Right>.TerminaliPhoneTitleH2{
        font-size: 22px;
    }

    .TerminaliPhoneIn>.StandartIn{
        padding-right: 0;
        padding-left: 0;
    }
    .TerminaliPhoneInPart02Right>.TerminaliPhoneTitleH2,.TerminaliPhoneInPart03Left>.TerminaliPhoneTitleH2{
        padding-top: 20px;
    }
    .TerminaliPhoneInPart02,.TerminaliPhoneInPart03{
        padding-top: 0;
    }
}
@media only screen and (max-width: 430px) {
    .TerminaliPhoneInPart01Right{
        height: auto;
    }
    .TerminaliPhoneInPart02Left>img,.TerminaliPhoneInPart03Right>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 400px) {
    .TerminaliPhoneInPart01LeftStore{
        line-height: 40px;
        padding-left: 2px;
        padding-right: 20px;
    }
    .TerminaliPhoneInPart01LeftText{
        line-height: 30px;
        padding-left: 2px;
        padding-bottom: 10px;
    }
}


@media only screen and (max-width: 330px) {
    .TerminaliPhoneInTitleGridButtons>a{
        margin-left: 2px;
    }
}


.TerminaliPhoneInTitleGridButtons>a:nth-of-type(1)>.ButtonTerminalMobile{
    background: none;
    border: 1px solid #eceae6;
    color: #0069d4;
}