.BonusPage{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.BonusTitle{
    font-size: 24px;
    color: #075a89;
    padding-left: 62px;
}

.BonusPageTop{
    display: grid;
    grid-template-columns: 259px auto;
    grid-column-gap: 100px;
    margin-top: 25px;
    padding-top: 15px;
    /*background-color: antiquewhite;*/
    background: url("../../../static/img/Bonus/bonus_fon.jpg");
    background-repeat: no-repeat;
}

.BonusPageTopLeftIMG{
    width: 259px;
    height: 201px;
    margin-left: 55px;
    background: url("../../../static/img/Bonus/Bonus00.png");
}

.BonusPageTopLeftRight{
    padding-left: 10px;
    font-weight: 900;
    font-size: 38px;
    padding-top: 30px;
    color: black;
}

.BonusPageTopLeftRightTitle{
    margin-bottom: 20px;
    padding-right: 30px;
}

.BonusPage01{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
}
.BonusPage01Line{
    height: 3px;
    width: 100%;
    background-color: #d9e7ee;
}

.BonusPage01Text{
    padding-left: 50px;
    padding-right: 50px;
    color: #005f8a;
    font-size: 30px;
}

.BonusPage01Out{
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
}

.BonusPage02{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 35px;
}

.BonusPage02Element{
    display: grid;
    grid-template-rows: 90px 81px auto;
    margin-right: 40px;
    margin-left: 40px;
    align-items: center;
    text-align: center;
}

.BonusPage0202Text{
    align-self: start;
}

.BonusPage02Title{
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid #dedede;
    height: 40px;
}


.BonusPage02Text{
    margin-top: 25px;
    font-size: 18px;
    color: #a58f9b;
    align-self: flex-start;
}

.BonusPage01>.ButtonOut{
    margin-left: 20px;
    margin-right: 20px;
}

.BonusPageH1{
    text-align: center;
    font-size: 30px;
    color: #005f8a;
    margin-top: 70px;
}

.BonusPage03Grid{
    margin-top: 75px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
}

.BonusPage03GridElement{
    display: grid;
    grid-template-columns: 22px auto;
    grid-column-gap: 10px;
}

.BonusPage03GridElementLeft{
    grid-column: 1;
    margin-bottom: 20px;
}

.BonusPage03GridElementRight{
    grid-column: 2;
    grid-row: 1/span 5;
}

.BonusPage03GridElement{
    color: #7b8f9b;
    font-size: 17px;
}

.BonusPage03GridElementTextBold{
    line-height: 30px;
    margin-top: 20px;
    font-weight: 500;
    color: #005b85;
}

.BonusPage05{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    color: #7b8f9b;
    font-size: 17px;
    margin-top: 80px;
}

.BonusPageTopLeftRight>.ButtonOut{
    justify-content: flex-start;
    margin-bottom: 30px;

}

@media only screen and (max-width: 850px) {
    .BonusPageTopLeftRight{
        padding-left: 0;
    }
}

@media only screen and (max-width: 720px) {
    .BonusPageTop{
        grid-template-columns: auto;
        justify-content: center;
    }
    .BonusPageTopLeftIMG{
        margin: auto;
    }
    .BonusPageTopLeftRight{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 26px;
    }
    .BonusPageTopLeftRight>.ButtonOut{
        justify-content: center;
    }
    .BonusPage01Line{
        background: none;
    }
    .BonusPage01Text{
        padding: unset;
        text-align: center;
    }
    .BonusPage02{
        grid-template-columns: auto;
        text-align: center;
    }
    .BonusPage03Grid{
        grid-template-columns: auto;
    }
    .BonusPage03GridElementRight{
        grid-row: unset;
        grid-column: 1;
    }
    .BonusPage05{
        grid-template-columns: auto;
        grid-row-gap: 20px;
    }
    .BonusPageTopLeftRightTitle{
        text-align: center;
    }
    div>.BonusPage02Arrow01,div>.BonusPage02Arrow02{
        margin-top: 15px;
        transform: rotate(90deg);
    }
    .BonusPageH1{
        padding-right: 15px;
        padding-left: 15px;
    }
}

.BonusPage02Arrow01,.BonusPage02Arrow02{
    margin-top: 100px;
}