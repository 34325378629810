.ProvLiq{
    max-width: 1078px;
    margin: auto;
}

.ProvLiqGridElement,.ProvLiqGridElementsActive{
    display: grid;
    justify-content: center;
    align-content: center;
}

.ProvLiqTitle{
    font-family: GothamPro, sans-serif;
    font-weight: 400;
    font-size: 38px;
    color: rgb(24,24,24);
    padding-top: 47px;
    padding-bottom: 47px;
}

.ProvLiqGridElement{
    cursor: pointer;
}

.ProvLiq>.ProvLiqText{
    padding-left: 0;
    padding-top: 20px;
}
.ProvLiqText{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: rgb(24,24,24);
}

.ProvLiqGrid{
    margin-top: 47px;
    padding-bottom:  47px;
    display: grid;
    grid-template-columns: 60% 40%;
}


.ProvLiqGridInfo{
    grid-column: 2;
    background-color: white;
    height: 534px;
}

.ProvLiqGridElements>div>{
    background-color: white;
}

.ProvLiqGridElements{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 10px;
}

.ProvLiqGridElements>div{
    background-color: white;
    width: 262px;
    height: 262px;
    line-height: 400px;
    text-align: center;
}

@media only screen and (max-width: 1150px) {

    .ProvLiqTitle,.ProvLiqText{
        padding: 0 22px 22px 22px;
    }

    .ProvLiqGrid{
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-row-gap: 10px;
        padding: 22px;
        margin-top: 0;
    }
    .ProvLiqGridInfo{
        grid-column: 1;
        height: 256px;
    }
    .ProvLiqGridElements{
        grid-row: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .ProvLiqGridElement{
        margin-bottom: 10px;
        margin-left: 10px;
    }
    .ProvLiqGridElementsActive{
        margin-bottom: 8px;
        margin-left: 8px;

    }
}



.ProvLiqGridElementsActive{
    border: 4px solid #0a79bf;
}

.ProvLiqGridElementsActive{
    width: 256px !important;
    height: 256px !important;
}


.ProvLiqGridInfoTitle{
    font-family: GothamPro, sans-serif;
    font-weight: 400;
    font-size: 23px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (max-width: 600px) {
    .ProvLiqGridInfo{
        height: auto;
        padding-bottom: 32px;
    }
    .ProvLiqGridElements{
        justify-content: center;
    }
    .ProvLiqGrid{
        padding: 0;
    }
}

.ProvLiqGridInfoSite{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgb(33,142,210);
    padding-left: 40px;
    padding-right: 40px;
}

.ProvLiqGridInfoText{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    padding-left: 40px;
    padding-right: 40px;
}
