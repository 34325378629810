.ContestMenu{
    width: 180px;
}

.ContestMenuElement:hover{
    color: #f5a10f;
}

.ContestMenuElementText{
    text-align: center;
    font-size: 18px;
}


.ContestMenu>a{
    text-decoration: none;
}

.ContestMenuElement{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 52px;
}
@media only screen and (min-width: 600px) {
    .ContestMenuElementText {
        margin-top: 10px;
    }
}

.ContestMenuElementIMGYellow{
    display: none;
}

.ContestMenuElement:hover>img{
    display: none;
}
.ContestMenuElement:hover>.ContestMenuElementIMGYellow{
    display: block;
}

.ArrowUpHover{
    display: none;
}



@media only screen and (max-width: 600px) {
    .ContestMenu{
        grid-row: 1;
        width: 100%;
    }
    .ContestMenuElement{
        display: flex;
        flex-direction: unset;
        justify-content: flex-start;
        align-items: center;
    }
    .ContestMenuElement>img{
        margin-left: 40px;
        margin-right: 40px;
    }
    .ContestMenuOpen{
        max-width: 310px;
        margin: auto;
        padding: 10px 30px 30px 30px;
        border: 1px solid #075a89;
        border-radius: 20px;
        margin-bottom: 40px;
    }
    .ContestMenuOpen>a:first-of-type>div>img{
        width: 51px;
    }
    .ContestMenuOpen>a:nth-of-type(2)>div>img{
        width: 28px;
        padding: 10px 11px 16px;
    }
    .ContestMenuOpen>a:nth-of-type(3)>div>img{
        width: 44px;
        padding-right: 3px;
        padding-left: 3px;
    }

    .ContestMenuElementText{
        text-align: left;
    }
    .NoActiveElements{
        display: none;
    }
    .ContestMenuElement{
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .ActiveElements>.ContestMenuElement{
        border: 1px solid #075a89;
        border-radius: 32px;
        margin-right: 40px;
        margin-left: 40px;
        padding-top: 17px;
        padding-bottom: 17px;
    }
    .ActiveElements>div{
        color: #f5a10f;
    }
    .ActiveElements>div>img{
        display: none;
    }
    .ActiveElements>div>.ArrowUpHover{
        display: block;
        transform: rotate(180deg);
    }
    .ActiveElements>div>.ContestMenuElementIMGYellow{
        display: block;
    }
    .ActiveElements>.ContestMenuElement{
        justify-content: space-around;
    }

    .ContestMenuOpen>.NoActiveElements{
        display: block;
    }
    .ContestMenuOpen>.ActiveElements>.ContestMenuElement{
        border: none;
        margin: auto;
    }
    .ContestMenuOpen>.ActiveElements>.ContestMenuElement,.ContestMenuOpen>.ActiveElements>.ContestMenuElement{
        justify-content: flex-start;

    }

    .ContestMenuOpen>.ActiveElements>.ContestMenuElement>.ArrowUpHover{
        display: none;
    }
    .ContestRegistrationForm>.ContestRegistrationFormIn{
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 370px) {
    .ContestMenuElement>img{
        margin-left: 5px;
        margin-right: 5px;
    }
    .ContestRegistrationFormGrid>.ButtonOut>.Button3D{
        font-size: 12px;
    }
}
@media only screen and (max-width: 450px) {
    .ActiveElements>.ContestMenuElement{
        margin-left: 5px;
        margin-right: 5px;
    }
}