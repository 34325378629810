.CabinetHeader {
    grid-column: 5;
    grid-row: 2;
    justify-self: end;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;}

.CabinetHeader>input{
    display: none;
}

.HeaderPersonalAreaIMGPhone{
    display: none;
}

.header_key{
    display: flex;
    height: 15px;
    line-height: 15px;
    cursor: pointer;
}
.header_key_src{
    margin-top: -5px;
    margin-right: 3px;
}

.for_cab{
    display: block;
    background-color : #000;
    height : 100%;
    left : 0;
    opacity : 0.50;
    position : fixed;
    top : 0;
    width : 100%;
    z-index : 100;}

.cab_in{
    padding: 0 28px 28px 28px;
    position: fixed;
    z-index: 500;
    top: 130px;
    right: 50px;
    margin: auto;
    /*height : 420px;*/
    /*width : 380px;*/
    background-color: #E5ECFD;
    display: block;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    justify-content: space-between;
    align-items: center;}


.login_cab>h2{font-family: Roboto, sans-serif;
    font-size: 15px;}
.pass_cab>h2{font-family: Roboto, sans-serif;
    font-size: 15px;}
.up_cab>h1{font-family: Roboto, sans-serif;
    font-size: 21px;}
.pass_me>h2{font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #218ed2;}

.up_cab{
    grid-column: 1/ span 2; grid-row: 1;}
.x_close{font-family: Roboto, sans-serif;
    font-size: 21px;
    color: blue;
    grid-column: 2; grid-row: 1;
    justify-content: space-between;
    text-align: right;}
.login_cab{
    grid-column: 1; grid-row: 2;}
.login_enter_cab{
    grid-column: 2; grid-row: 2;}
.login_input_cab{
    width: 204px;
    height: 43px;}
.pass_input_cab{
    width: 204px;
    height: 43px;}
.pass_cab{grid-column: 1; grid-row: 3;}
.pass_enter_cab{grid-column: 2; grid-row: 3;}
.pass_me{grid-column: 1; grid-row: 4;}
.button_enter_cab{grid-column: 2; grid-row: 4;}

@media only screen and (max-width: 950px) {
    .CabinetHeader {margin-top: 15px;}
}
@media only screen and (max-width: 650px) {
     .CabinetHeader {grid-column: 2; grid-row: 1;
     }

}

.CabinetInAutorizationIn{
    display: flex;
    padding: 7px;
    border: solid 2px #0a56d0;
    border-radius: 5px;
}

.ButtonTransparencyInImgExit{
    margin-left: 10px;
    cursor: pointer;
}


@media only screen and (max-width: 500px) {
    .header_key{
        display:none;
    }
    .CabinetHeader{
        margin-top: 0;
        grid-column: 4;
    }
    /*.CabinetHeader>input{*/
    /*    display: block;*/
    /*}*/
    .CabinetInAutorizationIn{
        border: none;
    }
}

.iFrameCabinetCrest{
    position: absolute;
    right: 40px;
    top: 28px;
    cursor: pointer;
}

.iFrameCabinetLinks{
    position: absolute;
    z-index: 2;
    right: 20px;
    bottom: 60px;}

.iFrameCabinetLinksElement{
    margin-bottom: 5px;
}


@media only screen and (max-width: 1000px) {
    .CabinetHeader{
        grid-row: 1;
        grid-column: 3;
        margin-top: 0;
        justify-self: auto;
        display: flex;
        justify-content: flex-end;
        margin-right: 50px;
    }
    .HeaderPersonalArea{
        top: -1px;
    }
    .HeaderPersonalAreaIMG>img{
        width: 25px;
        height: 25px;
    }
    .HeaderPersonalAreaIMG{
        border-radius: 5px;
        height: 30px;
        width: 30px;
    }
}

    @media only screen and (max-width: 800px) {
        .CabinetHeader{
            grid-column: 4;
        }
        .CabinetHeader{
            align-items: center;
        }
        .HeaderPersonalAreaIMG{
            display: none;
        }
        .HeaderPersonalAreaIMGPhone{
            width: 35px;
            height: 35px;
            border: 2px #0a56d0 solid;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 7px;
        }
    }


@media only screen and (max-width: 450px) {
    .HeaderPersonalAreaText{
        display: none;
    }
}
@media only screen and (max-width: 550px) {
    .cab_in{
        right: 0;
        top: 10px;
        width: calc(100% - 20px);
    }
}
@media only screen and (max-width: 350px) {
    .iFrameCabinetLinks{
        position: relative;
        top: -10px;
        left: 20px;
    }
}
@media only screen and (max-width: 500px) {
    .EnterCabinetLeft{
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 5px 0 7px 0 rgba(0, 0, 0, 0.1);
        border: solid 2px #0a56d0;
        background-image: linear-gradient(to top, #3f86d4, #3065c5);
    }
    .EnterCabinetRight{
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
        background-image: linear-gradient(to top, #aba5d8, #b8ade8);
        border-top: solid 2px white;
        border-bottom: solid 2px white;

    }

}

@media only screen and (max-width: 360px) {
    .CabinetInAutorizationIn{
        padding-left: 1px;
        padding-right: 1px;
    }
    .HeaderTopLogo>a>img{
        width: 80px;
    }
}