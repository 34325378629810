.TISComponent{
    border: 1px solid #d8e4eb;
    border-radius: 5px;
    grid-column: 1/span 4;
}

.TISComponentTop{
    display: flex;
    justify-content: space-between;
    padding: 20px 26px 22px 22px;
}

.TISComponentTopText01{
    font-size: 19px;
    font-weight: 700;
    color: #181818;
}
.TISComponentTopText02{
    font-size: 15px;
    line-height: 23px;
    color: #181818;
}

.TISComponentTopButton>a{
    text-decoration: none;
}


.TisButtonGreen{
    height: 40px;
    width: 204px;
    background-image: linear-gradient(to top, #4ca800, #5bb500);
    display: flex;
    color: white;
    font-weight: 700;
    justify-content: space-around;
    align-items: center;
    border-radius: 3px;
}


.TisButtonGreenImg{
    position: relative;
    top: 3px;
    left: 5px;
}

.TISComponentDown{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 19px;
}

.TISComponentDownLeft{
    display: flex;
    justify-content: space-around;
}

.TISComponentDownRight{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.TISComponentDownRightElement{
    display: flex;
}

.TISComponentDownLeftElementBorder{
    width: 1px;
    height: 100%;
    border-left: 1px dashed #d4dfe6;
}

.TISComponentDownLeftElementTop{
    font-size: 15px;
    line-height: 28px;
    color: black;
}

.TISComponentDownLeftElementBottom>img{
    margin-right: 5px;
}
.TISComponentDownLeftElementBottom{
    color: #529b22;
    font-size: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.TISComponentDownRightElementText01{
    font-size: 13px;
    color: #aab9c2;
    margin-left: 5px;
}
.TISComponentDownRightElementText02{
    font-size: 13px;
    margin-right: 5px;
    margin-left: 5px;
}
@media only screen and (max-width: 1000px) {
    .TISComponent>.TISComponentDown{
        display: block;
    }
    .TISComponentDownRight{
        margin-top: 20px;
    }
    .TISComponentDownRight>div{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 700px) {
    .TISComponentTop{
        display: block;
    }
}
.TISComponentDownRightElementLeft{
    display: flex;
}
@media only screen and (max-width: 450px) {
    .TISComponentDownRight>.TISComponentDownRightElement{
        display: block;
        padding-left: 20px;
    }
    .TISComponentDownRightElementLeft{
        padding-bottom: 5px;
    }
    .TISComponentDownLeft{
        flex-wrap: wrap;
        justify-content: center;
    }
    .TISComponentDownLeftElement{
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    .TISComponentTop{
        padding-bottom: 0;
    }
}