.OtherformsPage{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.OtherformsPageH1{
    font-size: 24px;
    font-weight: 500;
    padding-top: 28px;
    color: #06466b;
}

.OtherformsPageGridTop{
    display: grid;
    grid-template-columns: 300px 318px 246px;
    grid-column-gap: 47px;
    margin-top: 28px;
}

@media only screen and (max-width: 1020px) {
    .OtherformsPageGridTop{
        grid-template-columns: 30fr 31fr 25fr;
    }
}


.OtherformsPageGridTopText{
    grid-column: 1/span 2;
    grid-row: 1/span 3;
    font-size: 18px;
    padding-bottom: 30px;
    line-height: 23px;
}

.OtherformsPageGridText{
    text-align: center;
    line-height: 1.28;
    font-size: 18px;
    margin-top: 10px;
}

.OtherformsPageGridTopIMG{
    grid-column: 1;
    grid-row: 4;
}
.OtherformsPageGridTopTitle{
    grid-column: 3;
    grid-row: 1;
    font-size: 24px;
    font-weight: 300;
    color: #06466b;
    text-align: center;
}

.OtherformsPageGridTopMounts{
    grid-column: 3;
    grid-row: 2;
    justify-self: center;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    width: 100%;
}
.OtherformsPageGridTopCup{
    grid-column: 3;
    grid-row: 3;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin-top: 40px;
}
.OtherformsPageGridTopWiFi{
    grid-column: 2;
    grid-row: 4;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin-top: 40px;
}
.OtherformsPageGridTopBuild{
    grid-column: 3;
    grid-row: 4;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin-top: 40px;
}

@media only screen and (max-width: 800px) {
    .OtherformsPageGridTop{
        grid-template-columns: auto 246px;
    }

    .OtherformsPageGridTopTitle{
        grid-column: 2;
        grid-row: 1;
    }
    .OtherformsPageGridTopMounts{
        grid-column: 2;
        grid-row: 2;
}
    .OtherformsPageGridTopCup{
        grid-column: 2;
        grid-row: 3;}
    .OtherformsPageGridTopWiFi{
        grid-column: 2;
        grid-row: 4;
}
    .OtherformsPageGridTopBuild{
        grid-column: 2;
        grid-row: 5;
}
    .OtherformsPageGridTopText{
        grid-column: 1;
        grid-row: 1/span 4;
}
    .OtherformsPageGridTopIMG{
        grid-column: 1;
        grid-row: 5;
    }
}

@media only screen and (max-width: 650px) {
    .OtherformsPageGridTop{
        grid-template-columns: auto;
    }

    .OtherformsPageGridTopTitle{
        grid-column: 1;
        grid-row: 3;
    }
    .OtherformsPageGridTopMounts{
        grid-column: 1;
        grid-row: 4;
        flex-direction: initial;
        max-width: initial;
}
    .OtherformsPageGridTopCup{
        grid-column: 1;
        grid-row: 5;
        flex-direction: initial;
        max-width: initial;
        justify-self: left;
    }
    .OtherformsPageGridTopWiFi{
        grid-column: 1;
        grid-row: 6;
        flex-direction: initial;
        max-width: initial;
        justify-self: left;
}
    .OtherformsPageGridTopBuild{
        grid-column: 1;
        grid-row: 7;
        flex-direction: initial;
        max-width: initial;
        justify-self: left;
}
    .OtherformsPageGridTopText{
        grid-column: 1;
        grid-row: 2;
}
    .OtherformsPageGridTopIMG{
        grid-column: 1;
        grid-row: 1;
    }
    .OtherformsPageGridTop>div>img{
        margin-right: 15px;
    }
}


.OtherformsPageGridMain{
    background-color: #f2f7f9;
    display: grid;
    grid-template-columns: 270px 240px 240px 240px;
}
@media only screen and (max-width: 1020px) {
    .OtherformsPageGridMain{
        background-color: #f2f7f9;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.OtherformsPageGridMainTitle{
    color: #06466b;
    font-weight: 300;
    font-size: 36px;
    grid-column: 1;
    grid-row: 1;
    text-align: center;
    padding-top: 40px;
}
.OtherformsPageGridMainH1{
    color: #06466b;
    font-weight: 300;
    font-size: 24px;
    grid-column: 1;
    grid-row: 2;
    max-width: 200px;
    margin: auto;
    padding: 40px 10px 0 10px;
    line-height: 0.96;
}
.OtherformsPageGridMainText{
    color: black;
    font-weight: 300;
    font-size: 18px;
    grid-column: 2/span 4;
    grid-row: 1/span 3;
    padding-left: 60px;
    padding-top: 40px;
    background-color: white;
    line-height: 23px;
}

.Quick{
    grid-column: 1;
    grid-row: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    justify-self: center;
    padding-top: 40px;
    text-align: center;
}
.OtherformsPageGridMainSync{
    grid-column: 1;
    grid-row: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    justify-self: center;
    padding-top: 40px;
    text-align: center;
}
.OtherformsPageGridMainBank{
    grid-column: 2;
    grid-row: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    justify-self: center;
    padding-top: 40px;
    text-align: center;
}
.OtherformsPageGridMainPassword{
    grid-column: 3;
    grid-row: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    justify-self: center;
    padding-top: 40px;
    text-align: center;
}
.OtherformsPageGridMainStartup{
    grid-column: 4;
    grid-row: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    justify-self: center;
    padding-top: 40px;
    text-align: center;
}

.OtherformsPageGridMainTextSub{
    padding-top: 10px;
    padding-bottom: 60px;
}

.OtherformsPageGridMain{
    margin-top: 20px;
    margin-right: 25px;
}
@media only screen and (max-width: 1020px) {
    .OtherformsPageGridMain{

    margin-left: 25px;
}
}
.OtherformsPageGridMainQuick>div{

    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 800px) {
    .OtherformsPageGridMain{
        margin-left: 0;
        margin-right: 0;
        padding-left: 25px;
        /*padding-right: 25px;*/
    }
    .OtherformsPageGridMain{
        grid-template-columns: 1fr 1fr 1fr;
    }
.OtherformsPageGridMainTitle{
    grid-column: 1;
    grid-row: 1;
}
.OtherformsPageGridMainH1{
    grid-column: 1;
    grid-row: 2;
}
.OtherformsPageGridMainText{
    grid-column: 2/span 3;
    grid-row: 1/span 4;
    display: flex;
    align-items: center;
}

.OtherformsPageGridMainQuick{
    grid-column: 1;
    grid-row: 3;
}


.OtherformsPageGridMainSync{
    grid-column: 1;
    grid-row: 4;
}
.OtherformsPageGridMainBank{
    grid-column: 1;
    grid-row: 5;
}
.OtherformsPageGridMainPassword{
    grid-column: 2;
    grid-row: 5;
}
.OtherformsPageGridMainStartup{
    grid-column: 3;
    grid-row: 5;
}
.OtherformsPageGridMainText{
    padding-right: 15px;
}
}


@media only screen and (max-width: 650px) {

    .OtherformsPageGridMainH101{
        margin-bottom: 15px;
    }
    .OtherformsPageGridMain{
        grid-template-columns: auto;
    }
    .OtherformsPageGridMainTitle{
        grid-row: 1;
    }
    .OtherformsPageGridMainH1{
        grid-column: 1;
        grid-row: 3;
        max-width: unset;
    }
    .OtherformsPageGridMainText{
        grid-column: 1;
        grid-row: 2;
        background-color: inherit;
    }

    .OtherformsPageGridMainQuick{
        grid-column: 1;
        grid-row: 4;
        flex-direction: initial;
        display: flex;
        align-items: center;
        max-width: none;
        justify-self: initial;
        padding-left: 20px;
    }
    .OtherformsPageGridMainSync{
        grid-column: 1;
        grid-row: 5;
        flex-direction: initial;
        display: flex;
        align-items: center;
        max-width: none;
        justify-self: initial;
        padding-left: 20px;
    }
    .OtherformsPageGridMainBank{
        grid-column: 1;
        grid-row: 6;
        flex-direction: initial;
        display: flex;
        align-items: center;
        max-width: none;
        justify-self: initial;
        padding-left: 20px;
    }
    .OtherformsPageGridMainPassword{
        grid-column: 1;
        grid-row: 7;
        flex-direction: initial;
        display: flex;
        align-items: center;
        max-width: none;
        justify-self: initial;
        padding-left: 20px;
    }
    .OtherformsPageGridMainStartup{
        grid-column: 1;
        grid-row: 8;
        flex-direction: initial;
        display: flex;
        align-items: center;
        max-width: none;
        justify-self: initial;
        padding-left: 20px;
    }
    .OtherformsPageGridMain{
        padding-bottom: 30px;
    }
    .OtherformsPageGridMain>div>.OtherformsPageGridMainIMG{
        width: 100px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
    }
.OtherformsPageGridMainTextSub{
    padding: unset;

}
    .OtherformsPageGridMainBank{
        text-align: left;
    }
    .OtherformsPageGridTopIMG{
        margin-bottom: 10px;
    }
    .OtherformsPageGridMainText{
        padding-left: 0;
    }
}