.ContactPage{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.ContactPageTitle{
    font-size: 48px;
    color: #075a89;
}

.ContactPageTop{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 65px;
}

.ContactPageTopLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContactPageTopLeft>img{
    border-radius: 50%;
}

.ContactPageTopLeftTitle{
    font-size: 24px;
    padding-top: 10px;
    color: #075a89;
    text-decoration: underline;
}
.ContactPageTopLeftText{
    font-size: 24px;
    color: #828282;
}

.ContactPageTopRight{
    display: flex;
    align-items: center;
}
.ContactPageTopRightTop{
    font-size: 20px;
    color: #666768;
    border-left: #666768 solid 5px;
    padding-left: 12px;
}

.ContactPageTopRightTitle{
    font-size: 18px;
    padding-top: 6px;
    padding-left: 12px;
    color: black;
    font-weight: 500;
    border-left: #828282 solid 5px;
}
.ContactPageTopRightText{
    font-size: 18px;
    padding-top: 6px;
    color: black;
    border-left: #828282 solid 5px;
    padding-left: 12px;
}

.ContactPageElements{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 19px;
    grid-row-gap: 19px;
    margin-top: 60px;
}
.ContactPageElement{
    border-radius: 4px;
    border: 1px solid #075a89;
}

.ContactPageElementText{
    padding: 0 19px 0 19px;
    font-size: 16px;
    color: #4d4d4d;
}

.ContactPageElementTitle{
    padding: 19px 19px 0 19px;
    font-size: 24px;
    color: black;
}
.ContactPageElementEmail{
    display: grid;
    grid-template-columns: 21px auto;
    align-items: center;
    grid-column-gap: 13px;
    padding: 15px 19px 0 19px;
    font-size: 18px;
    color: #075a89;
    /*text-decoration: underline;*/
}
.ContactPageElementClock{
    display: grid;
    grid-template-columns: 21px auto;
    align-items: center;
    grid-column-gap: 13px;
    padding: 15px 19px 0 19px;
    font-size: 18px;
    font-weight: 500;
    color: #454343;
    margin-bottom: 30px;
}

.ContactPageElementEmail>img{
    /*margin-top: 4px;*/
}
.ContactPageElementClock>img{
    /*margin-top: 3px;*/
}

@media only screen and (max-width: 650px) {
    .ContactPageElements {
        grid-template-columns: auto;
    }
}

@media only screen and (max-width: 650px) {
    .ContactPageTop{
        grid-template-columns: auto;
    }
    .ContactPageTopRight{
        margin-top: 32px;
    }
}

