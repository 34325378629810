.NewBieMenu{
    display: flex;
    margin-left: 20px;
}

.NewBieMenuElementText{
    padding: 9px 17px 6px 17px;
    /*margin-right: 20px;*/
    text-align: center;
}

.NewBieMenuActive>.NewBieMenuElementText{

    color: #f8e0c0;
    font-size: 16px;
    font-weight: 800;
}
.NewBieMenuActive{
    text-decoration: none;
    background-color: #075a89;
    border-radius: 14px;
}