.IndividualPage{
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-top: 30px;
}

.IndividualPageMain>img{
    float: right;
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 #b7b6b6;
}


.IndividualPageMain{
    margin-top: 28px;
}

.IndividualPageBottom{
    width: 424px;
    border: 1px solid #0a539c;
    display: grid;
    grid-template-columns: 116px auto;
    grid-column-gap: 40px;
    margin-top: 30px;
    border-radius: 10px;
    align-items: center;
}

.IndividualPageBottomIMG{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
}

.IndividualPageBottomRight{
    margin-top: -10px;
}

.IndividualPageBottomRightTitle{
    font-size: 18px;
    padding-top: 10px;
    color: black;
    border-left: 3px #0a539c solid;
    padding-left: 10px;
    font-weight: 500;
}
.IndividualPageBottomRight>a{
    line-height: 18px;
}
.IndividualPageBottomRight>a>div{
    border-left: 3px #449df5 solid;
    padding-left: 10px;
}



.IndividualPageBottomRightTitle{
    font-size: 18px;
    padding-top: 10px;
    color: black;
}
.IndividualPageBottomRightHREF{
    padding-top: 5px;
    color: #0a539c;
    text-decoration: underline;
}


@media only screen and (max-width: 650px) {
    .IndividualPageMain>img{
        float: unset;
        position: relative;
        display: block;
        margin: auto auto 30px;
    }
    .IndividualPageBottom{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .IndividualPageBottomRight{
        margin-bottom: 20px;
    }
    .IndividualPageBottom{
        width: 100%;
    }

}

@media only screen and (max-width: 380px) {
    .IndividualPageMain>img{
        width: 100%;
    }

    }