.ContestRegistrationForm{
    font-size: 16px;
    font-weight: 500;
    color: #888a8f;
}

.ContestRegistrationFormIn{
    padding: 54px 130px 54px 130px;
    background-color: #fcfcfc;
}

.ContestRegistrationFormSuccess{
    height: 100%;
}


.TraderContestFormRegistrationTitle{
    color: rgb(24,24,24);
    font-size: 26px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.ContestRegistrationFormGrid>.ButtonOut{
    margin-top: 30px;
}

.ContestRegistrationFormGridRegistrationFormElement{
    /*display: grid;*/
    /*grid-template-columns: 250px 26px;*/
    /*grid-column-gap: 50px;*/
    margin-top: 20px;
}


.ContestRegistrationFormGridRegistrationFormElementTopText{
    font-size: 12px;
    text-align: left;
    color: #898989;
    display: block;
}



.ContestRegistrationFormGridRegistrationFormElementTopTextError,.ContestRegistrationFormGridRegistrationFormElementTopTextErrorCheck{
    display: none;
}


.ContestRegistrationFormGridRegistrationFormElementInputInput{
    width: 100%;
    object-fit: contain;
    border: solid 1px #c8c8c8;
    background-color: #ffffff;
    padding: 6px;
    font-size: 20px;
    font-weight: 600;
    line-height: 0.2;
    text-align: left;
    color: #1e1e1e;
}

.ContestRegistrationFormGridRegistrationFormElementInputIn{
    position: relative;
}

.ButtonContestRegistrationFormPassword{
    position: absolute;
    top: 3px;
    right: -13px;
    font-family: Intro, sans-serif;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
    width: 114px;
    height: 28px;
    padding-left: 13px;
    padding-right: 12px;
    line-height: 30px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    border: solid 1px #08b7c2;
    background-image: linear-gradient(to top, #09d0dd 1%, #09d0dd);
}




.ButtonContestRegistrationForm{
    font-family: Intro, sans-serif;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
    width: 217px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 42px;
    border-radius: 21px;
    text-align: center;
    cursor: pointer;
    border: solid 1px rgb(33,142,210);
    background-color: rgb(33,142,210);
    margin: 20px 0 0 300px;
}


.ButtonContestRegistrationForm:hover{
    border: 1px solid #ea4f40;
    transition: all .2s linear;

}

.ButtonContestRegistrationForm:active{
    border-color:  #ea4f40;
    color: white;
}

.ContestRegistrationFormSuccessBottomLink>a>.ButtonContestRegistrationForm{
    font-size: 15px;
}


.InvestRegPasswordOpen{
    position: absolute;
    top: 10px;
    left: 250px;
    cursor: pointer;
}

.InvestRegPasswordClose{
    position: absolute;
    top: 10px;
    left: 250px;
    cursor: pointer;
}

.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementInputInput{
    width: 261px;
}
.ContestRegistrationFormGridRegistrationFormElementPassword>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementInputInput{
    width: 209px;
}

.PasswordNoView>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.InvestRegPasswordOpen{
    display: none;
}

.PasswordNoView>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.InvestRegPasswordClose {
    display: block;
}

.PasswordView>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.InvestRegPasswordOpen{
    display: block;
}

.PasswordView>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.InvestRegPasswordClose{
    display: none;
}

.ContestRegistrationFormGridRegistrationFormElementTopTextError,.ContestRegistrationFormGridRegistrationFormElementTopTextErrorCheck{
    color: rgb(255,104,104);
    font-size: 13px;
    padding-top: 10px;
    width: max-content;
}

.ContestRegistrationFormGridRegistrationFormElementError>.ContestRegistrationFormGridRegistrationFormElementTop>.ContestRegistrationFormGridRegistrationFormElementTopText{
    display: none;
}

.ContestRegistrationFormGridRegistrationFormElementError>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextError{
    display: block;
}
.ContestRegistrationFormGridRegistrationFormElementError2>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextError2{
    display: block;
}
.ContestRegistrationFormGridRegistrationFormElementError3>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextError3{
    display: block;
}
.ContestRegistrationFormGridRegistrationFormElementError4>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextError4{display: block;}
.ContestRegistrationFormGridRegistrationFormElementError5>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextError5{display: block;}
.ContestRegistrationFormGridRegistrationFormElementError6>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextError6{display: block;}
.ContestRegistrationFormGridRegistrationFormElementErrorMail>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementTopTextErrorCheck{
    display: block;
}

.ContestRegistrationFormGridRegistrationFormElementError>.ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>.ContestRegistrationFormGridRegistrationFormElementInputInput{
    border: solid 1px #e52d50;
    background-color: #ffecee;
}

@media only screen and (max-width: 900px) {
    .ContestRegistrationFormGridRegistrationFormElement{
        grid-template-columns : 150px auto;
    }
    .ButtonContestRegistrationForm{
        margin-left: 200px;
    }
}

@media only screen and (max-width: 600px) {
    .ContestRegistrationFormGridRegistrationFormElement{
        display: block;
    }
    .ButtonContestRegistrationForm{
        margin-left: 0;
    }
}
@media only screen and (max-width: 400px) {
    .ContestRegistrationFormGridRegistrationFormElementInput>.ContestRegistrationFormGridRegistrationFormElementInputIn>input{
        width: 150px;
    }
    .InvestRegPasswordClose,.InvestRegPasswordOpen{
        left: 200px;
    }
}

.ContestRegistrationFormGridRegistrationFormElementTopTitle{
    padding-bottom: 10px;
}

.ContestRegistrationForm>.RegInvestNoVisible{
    display: none;
}

.RegInvestNoVisible{
    display: none;
}

.ContestRegistrationFormGridRegistrationFormElementTopTextTradingAccount{
    font-size: 15px;
    color: #47478a;
    font-weight: 500;
    padding-top: 10px;
}

.ContestRegistrationFormButtomText{
    padding-top: 15px;
}

@media only screen and (max-width: 800px) {
    .TraderContestFormRegistration{
        grid-template-columns: auto;
    }
    .TraderContestFormRegistrationLeftTop,.TraderContestFormRegistrationLeftBottom{
        grid-column: 1;
        width: 100%;
        margin: auto;
    }
    .TraderContestFormRegistrationLeftBottomIn{
        text-align: center;
    }
}


.ContestRegistrationFormSuccess{
    padding-top: 40px;
}

.ContestRegistrationFormSuccessTitle{
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #888a8f;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eef1f2;
    max-width: 500px;
}

.ContestRegistrationFormSuccessTitle>img{
    padding-right: 20px;
}

.ContestRegistrationFormSuccessAllInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 149px;
    padding-bottom: 20px;
}

.ContestRegistrationFormSuccessAllInfoElement{
    display: flex;
}



.ContestRegistrationFormSuccessAllInfoElementText{
    width: 300px;
    font-weight: 500;
    font-size: 16px;
    color: #075a89;
}
.ContestRegistrationFormSuccessAllInfoElementInfo{
    font-weight: 400;
    font-size: 16px;
    color: black;
}

.ContestRegistrationFormSuccessBottomLink{
    display: flex;
}


@media only screen and (max-width: 850px) {
    .ContestNewbieInfoGrid{
        display: block;
    }
    .ContestNewbieInfoGridPrizesAll{
        display: flex;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 600px) {
    .ContestRegistrationFormSuccessAllInfo{
        height: auto;
        padding-bottom: 0;
    }
    .ContestRegistrationFormSuccessAllInfoElementInfo{
        padding-top: 10px;
    }

    .ContestRegistrationFormSuccessAllInfoElement{
        display: block;
        padding-bottom: 20px;
    }
    .ContestRegistrationFormSuccessAllInfoElementText{
        width: auto;
    }
    .ContestRegistrationFormSuccessBottomLink>a>div{
        margin-top: 0;
    }
    .ContestNewbieRegGrid{
        grid-template-columns: auto;
    }
}
