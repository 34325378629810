.TraderClientOpen{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 800px) {
    .TraderClientOpen{
        display: block;
    }
}


.OpenRealDocuments{
    width: 145px;
    height: auto;
}

.OpenRealDocumentsTop{
    display: flex;
    align-items: center;
}

.OpenRealDocumentsTitle{
    font-size: 12px;
    line-height: 18px;
    color: #33363f;
}

.OpenRealDocumentsTop>img{
    margin-right: 12px;
}

.OpenRealDocumentsElement{
    display: flex;
}


.OpenRealDocumentsElement{
    margin-top: 18px;
}


.OpenRealDocumentsElementText>a{
    color: #075a89;
    font-size: 13px;
    font-weight: 400;
}

.TraderClientOpenMain {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.TraderRegistration,.TraderRegistrationSuccess{
    width: 100%;
    max-width: 683px;
    padding: 5px;
    margin-right: 47px;
}


.TraderRegistrationIn{
    background-color: #f8fafc;
    margin-top: 26px;
    padding-bottom: 26px;
}
.TraderRegistrationGridRegistrationFormElement{
    display: grid;
    grid-template-columns: 180px auto;
    margin: 0 26px 24px 26px;
}

.TraderRegistrationGridRegistrationForm>.TraderRegistrationPartPromo{
    margin-top: 24px;
}

.TraderRegistrationGridRegistrationFormElement3{
    display: grid;
    grid-template-columns: 160px 220px auto;
    grid-column-gap: 20px;
    margin: 0 26px 15px 26px;
}

.TraderRegistrationGridRegistrationFormElementTopTitle{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    text-align: right;
}
.TraderRegistrationGridRegistrationFormElement>.TraderRegistrationGridRegistrationFormElementTopTitle{
    margin-right: 20px;
}

.TraderRegistrationGridRegistrationFormElementInput{
    width: 100%;
}

.TraderRegistrationGridRegistrationFormElementInput>input,.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>input{
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    max-width: 220px;
}

.TraderRegistrationGridRegistrationFormElementTopText{
    font-size: 11px;
    color: #898989;
    margin-left: 20px;
    margin-top: 6px;
}

.TraderRegistrationGridRegistrationFormElementTopTextPass{
    margin-top: 0;
}

.TraderRegistrationGridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 11px;
    color: #da343d;
    display: none;
}

.TraderRegistrationSmallInput{
    max-width: 180px;
}

.TraderRegistrationPasswordOpen,.TraderRegistrationPasswordClose{
    position: absolute;
    right: 5px;
    top: 6px;
}

.TraderRegistrationGridPassword{
    position: relative;
}

.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>div>img{
    cursor: pointer;
}
.PasswordNoView>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>div>.TraderRegistrationPasswordOpen{
    display: none;
}
.PasswordView>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>div>.TraderRegistrationPasswordClose{
    display: none;
}

.TraderRegistrationGridRegistrationFormElementTopTextError{
    grid-column: 2/span 3;
}

.ButtonTraderRegistrationPassword{
    font-size: 11px;
    color: #09518e;
    margin-left: 20px;
    margin-top: 7px;
    cursor: pointer;
    text-decoration: underline dotted;
}

.TraderRegistrationAllPasswordBackground{
    background-color: #f4f7f9;
    padding: 12px 0 12px 0;
}

.TraderRegistrationButtomText{
    font-size: 13px;
    margin-left: 26px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementTopTitle{
    color: #da343d;
}

.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridRegistrationFormElementTopTextError,.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementTopTextError {
    display: block;
}
.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>input,.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>input{
    background-color: #fff1f2;
    border-top: 1px solid #beb6b6;
    border-left: 1px solid #beb6b6;
}

.TraderRegistrationSuccess>.TraderRegistrationIn{
    padding: 0;
}

.TraderRegistrationGridRegistrationFormElement  {
    padding-right: 28px;
}

.TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn{
    padding: 26px;
}
.TraderRegistrationSuccessTitle{
    font-size: 23px;
    font-weight: 700;
}

.TraderRegistrationSuccessText{
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TraderRegistrationSuccessPasswordWithdrawal{
    background-color: unset;
    border: solid 2px #d52a3a;
    padding: 26px;
}

.TraderRegistrationSuccessAllInfo{
    margin-top: 15px;
}

.TraderRegistrationSuccessAllInfoElement{
    display: flex;
}

.TraderRegistrationSuccessAllInfoElementText{
    font-weight: 700;
    width: 250px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .TraderClientOpenMain {
        display: block;
    }
    .OpenRealDocuments{
        margin: auto;
    }
    .TraderRegistration{
        border: none;
        padding: 0;
    }
    .TraderRegistrationIn{
        background-color: unset;
    }
}

@media only screen and (max-width: 550px) {
    .TraderRegistrationGridRegistrationFormElement{
        display: block;
    }
    .TraderRegistrationGridRegistrationFormElementTopTitle{
        margin-bottom: 12px;
    }
    .TraderRegistrationGridRegistrationFormElement3{
        grid-template-columns: auto;
    }
    .TraderRegistrationGridRegistrationFormElementTopText{
        grid-row: 2;
        margin-left: 0;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 17px;
    }
    .TraderRegistrationGridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .TraderRegistrationSmallInput{
        max-width: unset;
    }
    .TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementTopTitle{
        margin-bottom: 0;
        grid-row: 1;
    }
    .TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementTopTextError{
        grid-column: 1/span 2;
    }
    .TraderRegistrationGridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .ButtonTraderRegistrationPassword{
        grid-row: 1;
        margin-left: 0;
        position: relative;
        right: 20px;
        top: 5px;
    }
    .ButtonTraderRegistration{
        width: unset;
    }
    .TraderRegistrationAllPasswordBackground>.TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementTopTitle{
        padding-bottom: 10px;
    }
    .TraderRegistrationAllPasswordBackground>.TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword{
        width: 90%;
    }
    .ButtonTraderRegistration{
        max-width: 90%;
        margin: 20px auto auto;
        width: 70%;
        margin-bottom: 20px;
    }

    .TraderClientOpen>.StandartPage>.StandartTitle{
        text-align: center;
    }
    .TraderRegistrationSuccess{
        border: none;
    }
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn{
        padding: 0;
    }
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.TraderRegistrationSuccessTitle,
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.TraderRegistrationSuccessAllInfo,
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.TraderRegistrationSuccessText
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .TraderRegistrationSuccessText{
        line-height: 27px;
    }
    .TraderClientOpen>.StandartPage>.TraderClientOpenMain>.TraderRegistrationSuccess{
        padding: 0;
    }
}

@media only screen and (max-width: 800px) {
    .OpenRealDocuments{
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
    }
    .TraderRegistrationGridRegistrationFormElementTopTitle{
        text-align: left;
    }
    .TraderRegistrationIn{
        background-color: #f8fafc;
    }
}

@media only screen and (max-width: 600px) {
    .TraderRegistrationGridPassword>div>img{
        left: 200px;
    }
}

.TraderRegistrationSelectTypeMicro{
    background-color: white;
    display: flex;
}
.TraderRegistrationSelectTypeMicroElement>a>img{
    position: absolute;
    top: 9px;
    /*right: 9px;*/
    margin-left: 10px;
}
.TraderRegistrationSelectTypeMicroElement{
    position: relative;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #33363f;
    cursor: pointer;
}
.TraderRegistrationSelectTypeMicroElementActive{
    background-color: #f8fafc;
    cursor: unset;
}

.TraderRegistrationGridRegistrationFormTitle{
    color: #33363f;
    border-bottom: 1px solid rgba(0,0,0,0.07);
    margin: 24px 50px 40px;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-left: 15px;
}

.TraderRegistrationSelectTypeInfo{
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.TraderRegistrationSelectTypeInfoElement{
    padding: 18px 30px;
    color: #a2a6b1;
    text-decoration: underline;
    cursor: pointer;
}
.TraderRegistrationSelectTypeInfoElementActive{
    background-color: #075a89;
    color: white;
    text-decoration: none;
    cursor: unset;
}
.TraderClientOpenSuccess>div{
    width: 100%;
}
.TraderClientOpenSuccess>div>.StandartTitleOut{
    display: none;
}
.TraderClientOpenSuccess>div>div {
    margin-left: 20px;
    margin-right: 20px;
}
.TraderClientOpenSuccess>div>div>.TraderRegistrationSuccess  {
    max-width: none;
}

.TraderClientOpenSuccess>div>div>div>.TraderRegistrationIn{
    /*max-width: 1200px;*/
    margin-top: 0;
}
.StandartTitleSuccess{
    font-size: 18px;
    color: #075a89;
    font-weight: 500;
    margin-bottom: 10px;
}
.StandartTitleSuccessBottom{
    font-size: 12px;
    color: #fd9a0f;
    font-weight: 500;
    /*margin-bottom: 10px;*/
}

.StandartTitleSuccessOutOut{
    display: none;
}

.TraderClientOpenSuccess>div>.StandartTitleSuccessOutOut{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.TraderRegistrationSuccessPasswordWithdrawal02{
    margin-top: 10px;
    color: #d52a3a;
}
.TraderRegistrationSuccessPasswordWithdrawalTop>img{
    margin-right: 10px;
}
.TraderRegistrationSuccessPasswordWithdrawalTop{
    cursor: pointer;
    margin-bottom: 20px;
    color: #0c95bb;
    text-decoration: underline;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    align-items: center;
}

@media print {
    footer,header,.MainMenu,.MainMenuOut,.IfYouHaveQuestions,.OpenRealDocuments,.TraderRegistrationSuccessPasswordWithdrawalTop,.ButtonTraderRegistration{
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .OpenRealDocumentsTop{
        margin-top: 40px;
    }
}
@media only screen and (max-width: 400px) {
    .TraderRegistrationSelectTypeInfoElement{
        padding-left: 15px;
        padding-right: 15px;
    }
}