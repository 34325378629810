.TradingFAQButtonButtonAllDivActive,.TraderFAQElementTitle{
    cursor: pointer;

}

.TradingFAQButtonButtonAllDivActive{
    color: #218ed2;
}


.FAQElement{
    cursor: pointer;
}

.TradingFAQPartTitle {
    margin-top: 40px;
    padding-bottom: 15px;
    padding-left: 0;
    font-weight: 800;
    font-size: 13px;
    color: rgb(24,24,24);
}

.TitleGlobalFlex{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 650px) {
    .TitleGlobalFlex{
        flex-direction: column;

    }

    .TitleGlobalFlex>.TradingFAQButtonButtonAll{
        margin-top: 0;
    }
}
@media only screen and (max-width: 400px) {
    .TitleGlobalFlex>.TradingFAQButtonButtonAll{
        grid-template-columns: 157px;
        grid-row-gap: 10px;
        padding-bottom: 10px;
    }
}



.TradingFAQButtonButtonAll{
    display: grid;
    grid-template-columns: 157px 157px;
    grid-column-gap: 20px;
    position: absolute;
    right: 0;
    width: 334px;
}

.TradingFAQButtonButtonAll>div>img{
    padding-right: 15px;
}

.TradingFAQButtonButtonAll>.TradingFAQButtonButtonAllDivActiveNo{
    border: 2px solid #9cc8e1;
    line-height: 22px;
    color:  #9cc8e1;
}

.TradingFAQButtonButtonAll>.TradingFAQButtonButtonAllDivActive{
    border: 2px solid #fc9a0f;
    background-color: #fc9a0f;
    color: white;
    line-height: 22px;
}

.TradingFAQIMGActive{
    display: none;
}
.TradingFAQButtonButtonAllDivActive>.TradingFAQIMGActive{
    display: block;
}
.TradingFAQButtonButtonAllDivActive>.TradingFAQIMG{
    display: none;
}

.TradingFAQButtonButtonAll>div{
    width: 129px;
    border-radius: 4px;
    background-color: white;
    padding: 10px 15px 10px 13px;
    height: 22px;
    display: flex;
    align-items: center;
}
.TradingFAQButtonButtonAllEnglishVersion>div{
    width: 113px;
    padding-left: 26px;
}

@media only screen and (max-width: 800px) {
    .TradingFAQButtonButtonAll{
        position: relative;
    }
}

.TraderFAQ{
    position: relative;
    max-width: 1000px;
    margin: auto;
    margin-top: 30px;
    padding-bottom: 15px;
}

@media only screen and (max-width: 1150px) {
    .TraderFAQ{
        margin-left: 27px;
        margin-right: 27px;
    }
}
.TradingFAQPartElement{
    position: relative;
}
.TradingFAQPartElement::before{
    width: 4px;
    background-color: #fc9a0f;
    position: absolute;
    left: 5px;
    top: 7px;
    content: '';
    border-top: 4px solid #fc9a0f;
}

.TradingFAQPartElement{
    background-color: white;
    padding: 0 58px 15px 19px;
    margin-bottom: 10px;
}

.TradingFAQPartElement>.TradingFAQPartElementTitle{
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: rgb(10,121,191);
    cursor: pointer;
}

.TradingFAQPartElement>.TradingFAQPartElementText{
    display: none;
}



.TradingFAQPartElementActive>.TradingFAQPartElementTitle{
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 17px;
    color: rgb(24,24,24);
}

.TradingFAQPartElementActive>.TradingFAQPartElementText{
    display: block;
    margin-top: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgb(24,24,24);
}


@media only screen and (max-width: 800px) {
    .TraderFAQ {
        margin-right: 10px;
        margin-left: 10px;
    }
}