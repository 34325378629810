.MetatraderPage{
    max-width: 1000px;
    margin: auto;
}

.MetatraderGrid{
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-column-gap: 40px;
}

.MetatraderGridLeft{
    background-image: url("../../static/img/Terminal/Metatrader4/mt401.jpg");
    background-repeat: no-repeat;
    background-position: right;
}

.MetatraderTitle{
   font-size: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #075a89;
}

.MetatraderText{
    font-size: 13px;
    line-height: 19px;
}

.MetatraderUL{
    font-size: 13px;
    line-height: 19px;
}

.Metatrader02{
    margin-top: 30px;
}

.MetatraderHREF,.MetatraderHREF>a{
    display: flex;
    align-items: center;
}

.MetatraderHREF{
    margin-bottom: 10px;
}

.MetatraderHREF>a{
    color: #075a89;
}

.MetatraderHREF>a>div{
    margin-left: 10px;
    margin-right: 10px;
}

.MetatraderHREF02{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.MetatraderHREF02>a {
    color: #075a89;
    padding-left: 15px;
}

.MetatraderBottomTitle{
    color: #005b85;
    font-size: 18px;
}

.MetatraderBottomGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
}

.MetatraderBottom{
    margin-top: 40px;
    padding-right: 15px;
    padding-left: 15px;
}

.MetatraderBottomGridElementText>a{
    padding-left: 5px;
}
.MetatraderBottomGridElement{
    display: grid;
    grid-template-columns: 29px auto;
    grid-column-gap: 17px;
}
.MetatraderBottomGridElementNumber{
    width: 29px;
    height: 29px;
    background-color: #00aef3;
    border-radius: 50%;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    line-height: 34px;
    padding-top: 1px;
}

.MetatraderBigIMG{
    width: 100%;
    min-width: 300px;
}
@media only screen and (max-width: 800px) {
 .MetatraderGrid{
     display: block;
 }
    .MetatraderBottomGrid{
        display: block;
    }
    .MetatraderBottomGridElement{
        margin-top: 20px;
        align-items: center;
    }
    .MetatraderBottomGridElementNumber{
        position: relative;
        top: -3px;
    }
}

.MetatraderPagePDFDocumentation>a{
    margin-left: 20px;
    margin-bottom: 15px;
}