
.PageTerminal{
    background-color: white;
}

.PageTerminal>.MainMenuGlobal{
    max-width: 1000px;
    height: 99px;
}

.TerminalAndroid{
    font-family: Geometria, sans-serif;
}

.TerminalAndroidIn{

    background-color: white;
    max-width: 1078px;
    margin: auto;
}

.TerminalAndroidInPart01{
    display: grid;
    grid-template-columns: 3fr 1fr auto;
    height: 638px;
    padding-top: 30px;
}

.TerminalAndroidTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
    grid-column: 1/span 3;
    grid-row: 1 ;
}

.TerminalAndroidInTitleGridButtons{
    height: auto;
    grid-column: 3;
    grid-row: 1;
    display: flex;
    justify-content: space-around;
}

.TerminalAndroidInTitleGridButtons>a{
    width: 122px;
    margin-left: 22px;
    height: 49px;
}


.TerminalAndroidInPart01Left{
    grid-column: 1;
    grid-row: 3;
}

.TerminalAndroidInPart01Right{
    grid-column: 2;
    grid-row: 2;
}

@media only screen and (max-width: 1050px) {

    .TerminalAndroidInTitleGridButtons>a{
        padding-bottom: 10px;
    }
    .TerminalAndroidInPart01{
        height: 800px;
    }
}


.TerminalAndroidInPartStore{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-column: 1 /span 3;
    grid-row: 2;
}


.TerminalAndroidInPart01Right{
    grid-column: 3;
    grid-row: 2 /span 3;
    position: relative;

}

@media only screen and (max-width: 950px) {
    .TerminalAndroidInPart01Right {
        grid-column: 3;
        grid-row: 3;
        position: relative;

    }
}

.TerminalAndroidInPart01LeftText{
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    color: #413422;
    padding-left: 26px;
}


.TerminalAndroidInPart01LeftTextMain{
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    line-height: 30px;
}

.TerminalAndroidInPart02{
    background-color: #f3f3ef;
    height: 518px;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 66px;
    padding-top: 37px;
}

.TerminalAndroidInPart01RightMainImage{
    position: absolute;
    top: 60px;
    left: -6px;

}

.TerminalAndroidInPart02Left{
    overflow : hidden;
}
.TerminalAndroidInPart02Left>img{
    position: relative;
    top: -120px;
}

.TerminalAndroidInPart02Right>ul,.TerminalAndroidInPart03Left>ul{
    padding: 0;
}

.TerminalAndroidInPart02RightiPhoneDesktop>.TerminalAndroidTitleH2{
    margin-top: 40px;
}

.TerminalAndroidInPart02Right>ul>li,.TerminalAndroidInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminalAndroidInPart02Right>ul>li:before,.TerminalAndroidInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminalAndroidInPart02Right>.TerminalAndroidTitleH2,.TerminalAndroidInPart03Left>.TerminalAndroidTitleH2{
    padding: 0 30px 0 0;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

.TerminalAndroidInPart03Left>div{
    padding-top: 0;
}

.TerminalAndroidInPart03{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-template-rows: auto;
    padding-top: 37px;
    position: relative;
    z-index: 10;
    background-color: white;
}


.TerminalAndroidInPart03Right>img{
    height: auto;
}


@media only screen and (max-width: 1050px) {
    .TerminalAndroidInPart02{
        height: auto;
        padding-top: 35px;
    }
    .TerminalAndroidInPart02Left>img{
        top: 0;
    }

}
@media only screen and (max-width: 700px) {
    .TerminalAndroidInPart02Right>.TerminalAndroidTitleH2,.TerminalAndroidInPart03Left>.TerminalAndroidTitleH2{
        padding-top: 20px;
    }
    .TerminalAndroidInPart03,.TerminalAndroidInPart02{
        padding-top: 0;
    }
}


@media only screen and (max-width: 900px) {
    .TerminalAndroidInTitleGridButtons{
        justify-content: center;
    }
    .TerminalAndroidInPart03{
        margin-top: 0;}

    .TerminalAndroidInPart01Right>div>.TerminalComponent3IMGCircles{
        margin-bottom: 20px;
    }

}


@media only screen and (max-width: 950px) {

    .TerminalAndroidInPart01LeftTextMain{
        font-size: 18px;
    }
    .TerminalAndroidiPhone>.TerminalAndroidIn>.TerminalAndroidInPart01>.TerminalAndroidInPart01Right{
        grid-column: 1/span 3;
    }
    .TerminalAndroidInTitleGridButtons{
        flex-direction: column;
        grid-row: 1 /span 2;
        justify-self: flex-end;
        align-self: flex-start;
    }
    .TerminalAndroidInPart03Right{
        height: auto;
    }

}



@media only screen and (max-width: 700px) {
    .TerminalAndroidInPart02Right,.TerminalAndroidInPart03Left,.TerminalAndroidInPart01LeftTextMain{
    padding-right: 10px;
    padding-left: 10px;
    }
    .TerminalAndroidInPartStore{
        justify-content: center;
    }

    .TerminalAndroidInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .TerminalAndroidTitle{
        grid-column: 1;
        grid-row: 1 ;
        text-align: center;
    }

    .TerminalAndroidInPartStore{
        grid-column: 1;
        grid-row: 3;
    }

    .TerminalAndroidInTitleGridButtons{
        flex-direction: row;
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
        margin-top: 10px;

    }



    .TerminalAndroidInPart01Left{
        grid-column: 1;
        grid-row: 5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .TerminalAndroidInPart01Right{
        top: 0;
        grid-column: 1;
        grid-row: 4;
        display: flex;
        justify-content: center;
    }

    .TerminalAndroidInPart02{
        top: 20px;
        background-color: white;
        grid-template-columns: auto;
    }
    .TerminalAndroidInPart02Left{
        margin: auto;
        overflow: hidden;
    }
    .TerminalAndroidInPart03{
        grid-template-columns: auto;
    }
    .TerminalAndroidInPart03Right{

        display: flex;
        justify-content: center;
    }


    .TerminalAndroidInPart02Right>.TerminalAndroidTitleH2{
        font-size: 22px;
    }

    .TerminalAndroidIn>.StandartIn{
        padding-right: 0;
        padding-left: 0;
    }
    .TerminalAndroidInPart02Left>img,.TerminalAndroidInPart03Right>img{
        width: 100%;
        object-fit: contain;
    }
    .TerminalAndroidInPart03{
        display: flex;
        flex-direction: column;
    }
    .TerminalAndroidInPart03Right{
        order: 1;
    }
    .TerminalAndroidInPart03Left{
        order: 2;
    }


}
@media only screen and (max-width: 430px) {
    .TerminalAndroidInPart01Right{
        height: auto;
    }

}

@media only screen and (max-width: 400px) {
    .TerminalAndroidInPart01LeftStore{
        line-height: 40px;
        padding-left: 2px;
        padding-right: 20px;
    }
    .TerminalAndroidInPart01LeftText{
        line-height: 30px;
        padding-left: 2px;
        padding-bottom: 10px;
    }
    .TerminalAndroidInPart03Right{
        order: 1;
    }
    .TerminalAndroidInPart03Left{
        order: 2;
    }
    .TerminalAndroidInPart03Right{
        display: block;
    }
}


@media only screen and (max-width: 330px) {
    .TerminalAndroidInTitleGridButtons>a{
        margin-left: 2px;
    }
}


.TerminalAndroidInTitleGridButtons>a:nth-of-type(1)>.ButtonTerminalMobile{
    background: none;
    border: 1px solid #eceae6;
    color: #0069d4;
}