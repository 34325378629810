.AboutPage{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.AboutTitle{
    font-size: 48px;
    padding-top: 58px;
    color: #075a89;
}

.AboutPage01{
    display: flex;
    align-items: center;
}

.AboutPage01>img{
    margin-left: 30px;
}

.AboutPage03Black{
    margin-top: 63px;
    background: gray;
    background: linear-gradient(30grad, #64625e, #1b1d1c);;
    display: grid;
    grid-template-columns: 1px 194px auto;
    grid-column-gap: 45px;
}



.AboutPage03BlackIMG{
    width: 100%;
    height: 100%;
    background-image: url("../../../static/img/About/about04.png");
    background-position: center;
    background-size: cover;
}



.AboutPage03BlackTitle{
    color: white;
    font-weight: 500;
    font-size: 24px;
    padding-top: 20px;
    line-height: 24px;

}
.AboutPage03BlackText{
    color: white;
    font-size: 18px;
    padding-bottom: 20px;
    padding-right: 20px;
    line-height: 24px;
}

.AboutPage03BlackText>span{
    font-weight: 500;
}

.AboutPage04Title{
    font-size: 36px;
    padding-top: 50px;
    color: #06466b;
}

.AboutPage04YellowBorderTitle{
    font-size: 24px;
    padding-top: 25px;
    padding-left: 15px;
}

.AboutPage04YellowBorder{
    margin-top: 25px;
    position: relative;
    border: 1.5px solid #eab144;
    min-height: 99px;
    border-radius: 5px;
}

.AboutPage04YellowBorderText{
    font-size: 18px;
    padding-top: 10px;
    padding-left: 15px;
}

.AboutPage05{
    display: flex;
    align-items: center;
}

.AboutPage05Title{
    font-size: 24px;
    padding-top: 25px;
}

.AboutPage05Left>.AboutPage05Text{
    padding-top: 15px;
}

.AboutPage05Left>div{
    padding-left: 15px;
}

.AboutPage05Left>span{
    margin-left: 15px;
}

.AboutPage05IMG01{
    margin-top: 25px;
}

.AboutPage07{
    margin-top: 15px;
    padding-left: 15px;
}

.AboutPage06Title{
    font-size: 24px;
    padding-top: 25px;
    padding-left: 12px;
}
.AboutPage06Text{
    font-size: 18px;
    padding-top: 15px;
    padding-left: 12px;
    padding-bottom: 12px;
}

.AboutPage05IMG02{
    display: none;
}

@media only screen and (max-width: 850px) {
    .AboutPage03BlackIMG{
        background-position-y: 20px;
        background-repeat: no-repeat;
        width: auto;
        height: auto;
        background-size: contain;
    }
}
@media only screen and (max-width: 650px) {
    .AboutPage05IMG01{
        display: none;
    }
    .AboutPage05IMG02{
        display: block;
        float: right;
        position: relative;
    }
    .AboutPage05>img{
        justify-self: flex-end;
        left: unset;
    }
    .AboutPage06Text{
        padding-bottom: 20px;
    }
    .AboutPage01{
        display: grid;
    }
    .AboutPage01>img{
        grid-row: 1;
        justify-self: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .AboutPage02{
        padding-top: 25px;
    }
    .AboutPage03Black{
        grid-template-columns: auto;
    }
    /*.AboutPage03BlackIMG{*/
    /*    width: 194px;*/
    /*    height: 113px;*/
    /*    background-image: url("../../../static/img/About/about02.png");*/
    /*    background-position: center;*/
    /*    background-size: cover;*/
    /*    grid-row: 3;*/
    /*    position: relative;*/
    /*    left: calc(100% - 214px);*/
    /*}*/

    @media only screen and (max-width: 650px) {
        .AboutPage03Black{
            /*background-image: linear-gradient(to top, #64625e, #1b1d1c);*/
            /*background: none;*/
            background-image: url("../../../static/img/About/gradient.svg");
            background-size: cover;
        }
        .AboutPage03BlackIMG{
            background-image: url("../../../static/img/About/about05.png");
            background-position: right;
            background-size: contain;
        }
    }
    .AboutPage03BlackIMG{
        /*width: 100%;*/
        height: 109px;
        background-repeat: no-repeat;
        /*background-image: url("../../../static/img/About/about05.png");*/
        /*background-position: center;*/
        /*background-size: cover;*/
        grid-row: 3;
        position: relative;
        /*left: calc(100% - 214px);*/
    }
    .AboutPage03BlackRight{
        padding-left: 18px;
        padding-right: 18px;
    }
    .AboutPage05{
        display: grid;
        grid-column: auto;
    }
    .AboutPage04YellowBorderText{
        padding-bottom: 60px;
    }
    .AboutPage04YellowBorder>.AboutPage04Services0101{
        bottom: 15px;
        top: unset;
        right: 165px;
        left: unset;
    }
    .AboutPage04YellowBorder>.AboutPage04Services0102{
        bottom: 30px;
        top: unset;
        right: 30px;
        left: unset;

    }
    .AboutPage04YellowBorder>.AboutPage04Services0103{
        bottom: 10px;
        top: unset;
        right: 86px;
        left: unset;
    }
}

@media only screen and (max-width: 400px) {
    .AboutPage01>img{
        margin-left: 0;
    }
}

.AboutPage04Services0101{
    position: absolute;
    top: 9px;
    right: 147px;
    z-index: -1;
    opacity: 0.55;
}
.AboutPage04Services0102{
    position: absolute;
    top: 50px;
    right: 91px;
    z-index: -1;
    opacity: 0.55;
}
.AboutPage04Services0103{
    position: absolute;
    top: 9px;
    right: 23px;
    z-index: -1;
    opacity: 0.55;
}

.AboutPage06Left{
    margin-top: 20px;
    background-image: url("../../../static/img/About/fonAbout.svg");
    background-position: center;
    background-size: cover;
    min-height: 190px;
}

.AboutPage06{
    position: relative;
}
.AboutPage06Img{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

@media only screen and (max-width: 650px) {
    .AboutPage06Left{
        margin-top: 0;
    }
}
@media only screen and (max-width: 1020px) {
    .AboutPage06Left{
        border-left: #1c94d9 1px solid;
        border-right: #1c94d9 1px solid;
        padding-right: 15px;
    }
}
