@keyframes bannerOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.BannerStart{
    width: 100%;
    height: 294px;
    background: url("../../../static/img/Index/Banner05.png");
    position: relative;
    animation-name: bannerOpacity;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

.BannerStartRight{
    width: 500px;
    margin-left: 35px;
    justify-self: right;
    grid-row-gap: 20px;
    padding-top: 30px;
}

.BannerStartTitle{
    font-size: 36px;
    font-weight: 300;
    line-height: 50px;
    color: #e5e5e5;
    padding-left: 24px;
    background: url("../../../static/img/Index/banner06back.svg");

}

.BannerStartText{
    font-size: 14px;
    line-height: 20px;
    padding-top: 20px;
}

.BannerStartText>ul{
    list-style-type: none;
    padding-left: 0;
}

.BannerStartText>ul>li:before{
    content: "•";
    color: #0c355d;
    margin-right: 30px;
}
.BannerStartText>ul>li{
    margin-top: 10px;
    font-size: 14px;
    color: #0c355d;
}

.BannerStartButton{
    position: absolute;
    left: 370px;
    top: 170px;
}


