.ContestNewbieReg{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.ContestNewbieRegTitle{
    font-size: 24px;
    padding-top: 28px;
    color: #075a89;
    padding-left: 20px;
    margin-bottom: 50px;
}

.ContestNewbieRegGrid{
    display: grid;
    margin-bottom: 60px;
    grid-template-columns: auto 180px;
    grid-column-gap: 50px;
}

.ContestNewbieTopIMG{
    margin-top: 20px;
    background-image: url("../../../static/img/Contest/ContestNewbie01.png");
    background-position: center;
    height: 225px;
    border-top-right-radius: 10px;
    margin-bottom: 60px;
}

.ContestNewbieRegText{
    font-size: 18px;
    line-height: 23px;
    color: #484848;
    margin-left: 20px;
}
.ContestNewbieInfoGridDateTitle{
    font-size: 24px;
    color: #075a89;
}

.ContestNewbieInfoGrid>div{
    padding-top: 30px;
}
.ContestNewbieInfoGrid{
    margin-top: 40px;
    background-color: #fafafa;
    display: grid;
    grid-template-columns: auto auto;

    padding-bottom: 40px;
}

.ContestNewbieInfoGridDateTop{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContestNewbieInfoGridDateTitle{
    padding-top: 22px;
    padding-bottom: 40px;

}
.ContestNewbieInfoGridDate>.ContestNewbieInfoGridGray{
    margin-left: 20%;

}
@media only screen and (max-width: 500px) {
.ContestNewbieInfoGridDate>.ContestNewbieInfoGridGray{
    margin-left: 20px;

}
}
.ContestNewbieInfoGridGray{
    font-size: 17px;
    color: #939393;
    font-weight: 800;
    margin-top: 12px;
    margin-left: 20px;

}

.ContestNewbieInfoGridBlack{
    font-size: 17px;
    color: #000000;
    font-weight: 800;
}


.ContestNewbieInfoGridPrizeTitle{
    font-size: 24px;
    color: #075a89;
    padding-top: 22px;
    padding-bottom: 40px;
}

.ContestNewbieInfoGridPrizeTop{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContestNewbieInfoGridPrizesAll{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ContestNewbieRegistrationTitle{
    text-align: center;
    font-size: 32px;
    color: #075a89;
    padding-top: 40px;
    padding-bottom: 20px;
}
.ContestNewbieRegistrationTitle2{
    text-align: center;
    font-size: 18px;
    color: #8c8b90;
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .ContestNewbieRegTitle {
        margin-bottom: 5px;
    }
}
@media only screen and (max-width: 400px) {
    .ContestNewbieInfoGridPrize>.ContestNewbieInfoGridPrizesAll{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}