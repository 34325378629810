.TerminalAndroidTabletiPhone{
    font-family: 'Geometria', sans-serif;

}

.TerminalAndroidTabletIn{
    background-color: white;
}

.TerminalAndroidTabletTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
}

.TerminalAndroidTabletIn{
    font-family: Geometria, sans-serif;
}

.TerminalAndroidTabletInTitleGridButtons>a{
    width: 122px;
    margin-left: 22px;
}

.TerminalAndroidTabletInPart01{
    display: grid;
    grid-template-columns: 3fr 1fr auto;
    grid-template-rows: 50px 120px;
    padding-top: 30px;
    min-height: 600px;
}


.TerminalAndroidTabletTitle{
    grid-column: 1/span 3;
    grid-row: 1 ;
}

.TerminalAndroidTabletInTitleGridButtons{
    grid-column: 3;
    grid-row: 1;
    display: flex;
    justify-content: space-around;
}


.TerminalAndroidTabletInPart01Left{
    grid-column: 1;
    grid-row: 3;
    padding-bottom: 20px;
}

@media only screen and (max-width: 1050px) {

    .TerminalAndroidTabletInTitleGridButtons>a{
        padding-bottom: 10px;
    }
    .TerminalAndroidTabletInPart01Right{
        top: -40px;
    }
}


.TerminalAndroidTabletInPart02{
    padding-top: 40px;
}




.TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
    position: absolute;
    top: 132px;
    left: 43px;
}

.TerminalAndroidTabletInPartStore{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-column: 1 /span 3;
    grid-row: 2;

}

.TerminalAndroidTabletInPart01LeftStore>img{
    height: 50px;
}

.TerminalAndroidTabletInPart01LeftText{
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    color: #413422;
    padding-left: 26px;
}

.TerminalAndroidTabletInPart01Right{
    grid-column: 3;
    grid-row: 2 /span 3;
    position: relative;
    right: 50px
}

.TerminalAndroidTabletInPart01Right>img{
    position: relative;

    top: 60px;
}
@media only screen and (max-width: 1150px) {
    .TerminalAndroidTabletInPart01Right{
        left: -50px;
    }
}
@media only screen and (max-width: 1050px) {
    .TerminalAndroidTabletInPartStore{
        grid-column: 1 /span 3;
        grid-row: 2;
    }



}
@media only screen and (max-width: 950px) {
    .TerminalAndroidTabletInPart01Right {
        grid-row: 3;
        position: relative;

    }
}


.TerminalAndroidTabletInPart01LeftTextMain{
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    line-height: 30px;
}



.TerminalAndroidTabletInPart02{
    background-color: #f3f3ef;
    height: 518px;
    position: relative;
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-column-gap: 66px;}

.TerminalAndroidTabletInPart01RightMainImage{
    position: absolute;
    top: 60px;
    left: -6px;

}

.TerminalAndroidTabletInPart02Left>img{
    position: relative;
    right: 0;
}
.TerminalAndroidTabletInPart03Right{
    position: relative;
}

.TerminalAndroidTabletInPart02Right>ul,.TerminalAndroidTabletInPart03Left>ul{
    padding: 0;
    padding-right: 15px;
}

.TerminalAndroidTabletInPart02RightiPhoneDesktop>.TerminalAndroidTabletTitleH2{
    margin-top: 40px;
}


.TerminalAndroidTabletInPart02Right>ul>li,.TerminalAndroidTabletInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminalAndroidTabletInPart02Right>ul>li:before,.TerminalAndroidTabletInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminalAndroidTabletInPart02Right>.TerminalAndroidTabletTitleH2,.TerminalAndroidTabletInPart03Left>.TerminalAndroidTabletTitleH2{
    padding: 0 30px 0 0;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}


@media only screen and (max-width: 700px) {
    .TerminalAndroidTabletInPart02Right>.TerminalAndroidTabletTitleH2,.TerminalAndroidTabletInPart03Left>.TerminalAndroidTabletTitleH2 {
        padding-top: 20px;
    }
}

.TerminalAndroidTabletInPart03{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-template-rows: auto;
    padding-top: 37px;
    padding-bottom: 30px;
}

.TerminalAndroidTabletInPart03Right{
    height: 100%;
}

.TerminalAndroidTabletInPart03Right>img{
    height: auto;
}


@media only screen and (max-width: 1050px) {
    .TerminalAndroidTabletInPart02{
        height: auto;
    }
    .TerminalAndroidTabletInPart02Left>img{
        top: 0;
    }

}
@media only screen and (max-width: 900px) {
    .TerminalAndroidTabletInTitleGridButtons{
        justify-content: center;
    }
    .TerminalAndroidTabletInPart03{
        margin-top: 0;}
}


@media only screen and (max-width: 900px) {

    .TerminalAndroidTabletInPart01LeftTextMain{
        font-size: 18px;
    }
    .TerminalAndroidTabletiPhone>.TerminalAndroidTabletIn>.TerminalAndroidTabletInPart01>.TerminalAndroidTabletInPart01Right{
        grid-column: 1/span 3;
    }
    .TerminalAndroidTabletInTitleGridButtons{
        flex-direction: column;
        grid-row: 1 /span 2;
        justify-self: flex-end;
        align-self: flex-start;
    }
    .TerminalAndroidTabletInPart03Right{
        height: auto;
    }
    .TerminalAndroidInPart01RightMainImage{
        display: none;
    }
    .TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
        position: static;
    }

}

.TerminalAndroidTabletInTitleGridButtons{
    height: auto;
}

.TerminalAndroidTabletInTitleGridButtons>a{
    height: 49px;
}

@media only screen and (max-width: 700px) {
    .TerminalAndroidTabletInPart02Right,.TerminalAndroidTabletInPart03Left,.TerminalAndroidTabletInPart01LeftTextMain{
        padding-right: 10px;
        padding-left: 10px;
    }
    .TerminalAndroidTabletInPartStore{
        justify-content: center;
    }

    .TerminalAndroidTabletInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .TerminalAndroidTabletTitle{
        grid-column: 1;
        grid-row: 1 ;
        text-align: center;
    }

    .TerminalAndroidTabletInPartStore{
        grid-column: 1;
        grid-row: 3;
    }

    .TerminalAndroidTabletInTitleGridButtons{
        flex-direction: row;
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
        margin-top: 10px;

    }

    .TerminalAndroidTabletInPart01RightMainImage{
        display: none;
    }


    .TerminalAndroidTabletInPart01Left{
        grid-column: 1;
        grid-row: 5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .TerminalAndroidTabletInPart01Right{
        top: 0;
        grid-column: 1;
        grid-row: 4;
        display: flex;
        justify-content: center;
    }

    .TerminalAndroidTabletInPart03{
        grid-template-columns: auto;
    }
    .TerminalAndroidTabletInPart03Right{
        justify-content: center;
    }


    .TerminalAndroidTabletInPart02Right>.TerminalAndroidTabletTitleH2{
        font-size: 22px;
    }

    .TerminalAndroidTabletIn>.StandartIn{
        padding-right: 0;
        padding-left: 0;
    }

    .TerminalAndroidTabletInPart03{
        padding-top: 0;
    }
}
@media only screen and (max-width: 430px) {
    .TerminalAndroidTabletInPart01Right{
        height: auto;
    }
    .TerminalAndroidTabletInPart02Left>img,.TerminalAndroidTabletInPart03Right>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 400px) {
    .TerminalAndroidTabletInPart01LeftStore{
        line-height: 40px;
        padding-left: 2px;
        padding-right: 20px;
    }
    .TerminalAndroidTabletInPart01LeftText{
        line-height: 30px;
        padding-left: 2px;
        padding-bottom: 10px;
    }
}


@media only screen and (max-width: 330px) {
    .TerminalAndroidTabletInTitleGridButtons>a{
        margin-left: 2px;
    }
}

.TerminalAndroidTablet{
    font-family: 'Geometria', sans-serif;
}

.TerminalAndroidTabletTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
}

.TerminalIn{
    font-family: Geometria, sans-serif;
}


.TerminalAndroidTabletIn{
    font-family: Geometria, sans-serif;
}

.TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
    margin-bottom: 20px;

}

.TerminalAndroidTabletInPart01{
    display: grid;
    grid-template-columns: 3fr 1fr 500px;
    padding-top: 30px;
}

@media only screen and (max-width: 1050px) {
    .TerminalAndroidTablet>.TerminalAndroidTabletIn>.TerminalAndroidTabletInPart01>.TerminalAndroidTabletInPart01Right{
        grid-row: 3;
    }

}

@media only screen and (max-width: 615px) {
    .TerminalAndroidTablet>.TerminalAndroidTabletIn>.TerminalAndroidTabletInPart03>.TerminalAndroidTabletInPart03Right>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 900px) {
    .TerminalAndroidTabletInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .TerminalAndroidTabletInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .TerminalAndroidTabletTitle{
        grid-column: 1;
        grid-row: 1 ;
    }

    .TerminalAndroidTabletInPart01RightMainImage{
        display: none;
    }

    .TerminalAndroidTabletInPart01Left{
        grid-column: 1;
        grid-row: 5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .TerminalAndroidTablet>.TerminalAndroidTabletIn>.TerminalAndroidTabletInPart01>.TerminalAndroidTabletInPart01Right{
        top: 0;
        grid-column: 1;
        grid-row: 4;
        display: flex;
        justify-content: center;
    }
}

.TerminalAndroidTabletInPart02{
    height: auto;}


.TerminalAndroidTabletInPart02Left>img,.TerminalAndroidTabletInPart03Right>img{
    margin: auto;
}

@media only screen and (max-width: 615px) {
    .TerminalAndroidTabletInPart03Right{
        width: 100%;
    }
    .TerminalAndroidTabletInPart02Left{
        width: 100%;
    }
    .TerminalAndroidTabletInPart02Left>img,.TerminalAndroidTabletInPart03Right>img{
        width: 100%;
    }
}


.TerminalAndroidTabletPadInPart01RightMainImage{
    position: relative;
    top: 60px;
    left: -16px;
    width: 560px;
}

.TerminalAndroidTabletPadInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
    position: absolute;
    top: 130px;
    left: 38px;
}

.TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
    position: absolute;
    top: 10px;
    left: 238px;
}

@media only screen and (max-width: 900px) {
    .TerminalAndroidTablet>.TerminalAndroidTabletIn>.TerminalAndroidTabletInPart01>.TerminalAndroidTabletInPart01Right{
        grid-column: 1/span 3;
    }

    .TerminalAndroidTabletPadInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
        position: static;
        left: 0;
    }
    .TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
        position: static;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalComponent3IMGImage>img {
        width: 100%;
    }
}
@media only screen and (max-width: 615px) {
    .TerminalAndroidTabletInPart02Left{
        width: 100%;
    }
    .TerminalAndroidTabletInPart02,.TerminalAndroidTabletInPart03{
        padding-right: 0;
        padding-left: 0;
    }
}

@media only screen and (max-width: 1300px) {
    .TerminalAndroidTabletInPart02Left{
        position: relative;
    }
    .TerminalAndroidTabletInPart02{
        padding-left: 0;
        grid-template-columns: 4fr 3fr;
        position: relative;
    }
    .TerminalAndroidTabletInPart02>.TerminalAndroidTabletInPart02Left>img{
        position: absolute;
    }
    .TerminalAndroidTabletInPart02Left:before{
        content: '';
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: -525px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .TerminalAndroidTabletInPart02:before{
        content: '';
        height: 100%;
        width: 25px;
        position: absolute;
        top: 0;
        left: -25px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .TerminalAndroidTabletInPart03{
        display: grid;
        grid-template-columns: 400px auto;
        grid-column-gap: 50px;
        grid-template-rows: auto;
    }
    .TerminalAndroidTabletInPart03>.TerminalAndroidTabletInPart03Right{overflow: hidden;}


    .TerminalAndroidTabletInPart03>.TerminalAndroidTabletInPart03Right>img{
        position: absolute;
        left: -3px;
        overflow: hidden;

    }
    .TerminalAndroidTabletInPart03:after{
        content: '';
        height: 100%;
        width: 20px;
        position: absolute;
        top: 0;
        right: -20px;
        z-index: 10;
        background-color: white;
    }
}

@media only screen and (max-width: 615px) {
    .TerminalAndroidTabletInPart03>.TerminalAndroidTabletInPart03Right>img{
        left: 0;
    }
}
@media only screen and (max-width: 1100px) {
    .TerminalAndroidTabletInPart02{
        grid-template-columns: auto auto;
    }
    .TerminalAndroidTabletInPart02Left{
        overflow: hidden;
    }
}

@media only screen and (max-width: 615px) {
.TerminalAndroidTabletInPart02,.TerminalAndroidTabletInPart03{
    grid-template-columns: auto;
}
    .TerminalAndroidTabletInPart02>.TerminalAndroidTabletInPart02Left>img,.TerminalAndroidTabletInPart03>.TerminalAndroidTabletInPart03Right>img{
        position: relative;
    }
    .TerminalAndroidTabletInPart03{
        display: flex;
        flex-direction: column;
    }
    .TerminalAndroidTabletInPart03Right{
        order: 1;
    }
    .TerminalAndroidTabletInPart03Left{
        order: 2;
    }
    .TerminalAndroidTabletInPart03:after{
        width: 0;
    }

}
@media only screen and (max-width: 615px) {
    .TerminalAndroidTablet>.StandartIn{
        padding-left: 0;
        padding-right: 0;

    }
}

.TerminalAndroidTabletIn>.StandartIn{
    padding-right: 0;
}


@media only screen and (max-width: 900px) {
    .TerminalAndroidTabletInPart01Right{
        left: unset;
        grid-column: 1/span 3;
    }    .TerminalAndroidTabletInPart01Left{
             left: unset;
             grid-column: 1/span 3;
         }
    .TerminalComponent3IMGImage{
        width: auto;
    }
    .TerminalAndroidTabletInPart01Left{
        margin-top: 0;
    }
    .TerminalAndroidTabletInPart01LeftTextMain{
        padding-top: 0;
    }
}

.TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage>img{
    position: relative;
    left: -5px;
    top: 2px;

}
@media only screen and (max-width: 900px) {
    .TerminalAndroidTabletInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage>img{
        left: 0;
    }
    .TerminalAndroidTabletInPart01Right{
        right: 0;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalAndroidTabletInPart01Right,.TerminalAndroidTabletInPart01Left{
        grid-column: 1;
    }
    .TerminalAndroidTabletInPart01Left{
        padding-top: 20px;
    }

    .TerminalAndroidTabletInPart01{
        grid-template-rows: auto;
    }
}


.TerminalAndroidTabletInTitleGridButtons>a:nth-of-type(2)>.ButtonTerminalMobile{
    background: none;
    border: 1px solid #eceae6;
    color: #0069d4;
}