@keyframes bannerOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.BannerGilt{
    width: 100%;
    height: 294px;
    background: url("../../../static/img/Index/Banner04.png");
    position: relative;
    animation-name: bannerOpacity;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

.BannerGiltRight{
    justify-self: right;
    grid-row-gap: 20px;
    padding-top: 20px;
}

.BannerGiltTitle{
    border-left: 4px #0e87a8 solid;
    font-size: 36px;
    font-weight: 300;
    line-height: 50px;
    color: white;
    position: relative;
    left: 40px;
    top: 3px;
    padding-left: 30px;
}

.BannerGiltText{
    margin-left: 60px;
    font-size: 14px;
    line-height: 20px;
    padding-top: 20px;

}

.BannerGiltTextIn{
    display: flex;
    justify-content: left;
    width: 100%;
    margin-right: 30px;
    color: white;
    font-size: 18px;
    line-height: 30px;
}

.BannerGiltText01{
    border-right: 10px #4c626f solid;
    padding-right: 60px;
}
.BannerGiltText02{
    border-left: 3px white solid;
    padding-left: 60px;
    padding-right: 60px;
}

.BannerGiltText03{
    font-size: 11px;
    position: absolute;
    bottom: 15px;
    color: white;
    left: 60px;
}


.BannerGiltButton{
    margin-right: 30px;
}