.IndexBlocksOutOut{
    width: 100%;
    max-width: 1000px;
    height: 322px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .IndexBlocksOutOut {
        display: none;
    }
}

.IndexMainTopHrefElement{
    margin-left: 20px;
    margin-right: 20px;
}

.IndexMainTopHref{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
    .IndexMainTopHref {
        margin-top: 20px;
    }
    .IndexMainTopHrefElement{
        margin-top: 20px;
    }
}

.IndexMainTopHrefElement{
    display: grid;
    grid-template-rows: 50px auto;
    grid-row-gap: 20px;
    align-items: center;
    justify-content: center;
}

.IndexMainTopHrefElement>a{
    text-decoration: none;
}
.IndexMainTopHrefElement>a>div{
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: #33363f;
    font-weight: 500;
    margin-top: 5px;
    text-decoration: none;
}

.IndexBigElements{
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
}

.IndexNews{
    width: 284px;

}

.IndexNewsTop{
    width: 100%;
    height: 38px;
    padding-left: 38px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    background-color: #075a89;
}

.IndexNewsBody{
    margin-top: 33px;
}

.IndexNewsBodyElement{
    margin: 17px 26px 0 36px;
    padding-bottom: 14px;
    border-bottom: 1px solid #e5e5e5;
}

.IndexNewsBodyElementDate{
    color: #33363f;
    font-size: 11px;
    font-weight: 700;
    padding-bottom: 5px;
}

.IndexNewsBodyElementText{
    color: #33363f;
    font-size: 11px;
}

.IndexMT7{
    width: 377px;
}
.IndexMT7Title{
    width: 100%;
    height: 38px;
    padding-left: 38px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    background-color: #075a89;
}
.IndexMT7Text{
    justify-content: space-between;
}

.IndexMT7Body{
    display: flex;
    margin-top: 33px;
}

.IndexMT7BodyIMG{
    margin-right: 10px;
}

.IndexMT7Text{
    font-size: 12px;
    line-height: 18px;
}


.IndexMT7TextLink>a{
    text-decoration: underline;
    color: #0a56d0;
    font-size: 14px;
    padding-bottom: 5px;
}


.IndexLiquidity{
    width: 225px;

}

.IndexLiquidityIn{
    background-color: #fcb23c;

    background-image: url("../../static/img/Index/ProvidersBack.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    color: white;
}

.IndexLiquidityTitle{
    margin: 0 29px 11px 38px;
    padding-top: 20px;
    padding-bottom: 11px;
    border-bottom: white solid 1px;

}
.IndexLiquidityText{
    margin: 0 29px 11px 38px;
    padding-top: 20px;
    padding-bottom: 11px;
    font-size: 14px;
}
.IndexLiquidityHref{
    text-align: right;
    font-size: 11px;
    color: #986717;
    padding-bottom: 13px;
    padding-right: 21px;
    border-bottom: white solid 1px;
}

.IndexBigDPWD{
    margin-top: 30px;
}

.IndexBigDPWDTTop {
    display: grid;
    /*grid-template-columns: minmax(140px, 250px) auto  minmax(140px, 250px);*/
    grid-template-columns: auto 1fr auto;
}

.IndexBigDPWDTTopTitle{
    font-size: 14px;
    color: #33363f;
    margin-right: 26px;
}


.IndexBigDPWDTTopMiddle{
    align-self: center;
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
}

.IndexBigDPWDTTopHref{
    text-align: right;
    width: auto;
    font-size: 11px;
    color: #33363f;
    font-weight: 500;
    margin-left: 26px;
}

.IndexBigDPWDBottom{
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.IndexBigDPWDBottomElement>img{
    opacity: 0.5;
}

@media only screen and (max-width: 900px) {
    .IndexBigElements{
        display: grid;
        grid-template-columns: auto auto;
    }
    .IndexNews{
        grid-column: 1/span 2;
        width: 100%;
    }
    .IndexNewsTop{
        padding-left: 30px;
        width: calc(100% - 30px);
    }
    .IndexMT7Title{
        padding-left: 30px;
    }
    .IndexNewsBody{
        display: grid;
        grid-template-columns: auto auto auto;
    }
    .IndexNewsBodyElement{
        border-bottom: none;
    }
    .IndexMT7{
        margin-left: 60px;
    }
}

@media only screen and (max-width: 700px) {
    .IndexMT7{
        margin-left: 5px;
    }
}
@media only screen and (max-width: 630px) {
    .IndexBigElements{
        display: block;
    }

    .IndexNewsBody{
        display: block;
    }

    .IndexNewsBodyElement{
        border-bottom: 1px solid #e5e5e5;
    }
    .IndexNews{
        margin-bottom: 30px;
    }
    .IndexMT7{
        margin-left: 20px;
    }
    .IndexMT7Title{
        padding-left: 30px;
        width: calc(100% - 30px);
    }
    .IndexLiquidity{
        margin-left: 20px;
        margin-top: 30px;
    }
    .IndexMT7BodyIMG{
        padding-left: 20px;
    }
}


@media only screen and (max-width: 1050px) {
    .IndexBigDPWD{
        margin-left: 15px;
        margin-right: 15px;
    }
    .IndexBigDPWDBottom{
        flex-wrap: wrap;
    }
    .IndexBigDPWDBottomElement{
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media only screen and (max-width: 500px) {
    .IndexMT7{
        margin: auto;
        width: 100%;
    }
    .IndexLiquidity{
        width: 100%;
        margin-left: 0;
    }
    .IndexMT7Text{
        padding-right: 10px;
    }
}