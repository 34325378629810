.ContestPrizeMainGrid{
    display: grid;
    grid-template-columns: auto 180px;
    grid-column-gap: 50px;
}

.ContestPrizesGrid{
    display: flex;
}

@media only screen and (max-width: 900px) {
    .ContestPrizesGrid{
        display: block;
    }
}


.ContestPrizesGridLeft>img{
    width: 244px;
    height: 191px;
    position: relative;
    top: 27px;
    left: 44px;
}
.ContestPrizesGridLeft{
    width: 214px;
    height: 280px;
    margin-top: 30px;
    background-color: #075a89;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
    z-index: 5;
    left: 6px;
}
.ContestPrizesGridRight{
    color: #181818;
    background-color: #e7eff3;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 120px;
}

.ContestPrizesGridRightIn{
    padding: 26px 27px 27px 31px;
}

.ContestPrizesGridRightText01{
    font-size: 18px;
    line-height: 25px;
    color: #181818;
}

.ContestPrizesGridRightText01>b{
    padding-left: 5px;
}

.ContestPrizesGridRightTitle{
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    padding-top: 20px;
    margin-bottom: 30px;
}
.ContestPrizesGridOut {
    padding-right: 24px;
    padding-left: 21px;
}



.TraderContestTopGridTextListElement::before{
    content: "";
    width: 11px;
    height: 11px;
    background-color: #075a89;
    margin-bottom: 15px;
    position: absolute;
    left: -20px;
    top: 3px;
    display: flex;
    border-radius: 50%;
}

.TraderContestTopGridTextListElement{
    margin-left: 30px;
    display: flex;
    line-height: 23px;
    margin-bottom: 10px;
    color: #181818;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .ContestPrizeMainGrid{
        grid-template-columns: auto;
    }
}

@media only screen and (max-width: 900px) {
    .ContestPrizesGrid{
        display: block;
    }
    .ContestPrizesGridLeft{
        width: 100%;
        display: flex;
        justify-content: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        left: 0;
    }
    .ContestPrizesGridLeft>img{
        top: 30px;
        left: 0;
    }
    .ContestPrizesGridRight{
        padding-left: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

