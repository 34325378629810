.InvestDirect{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.ButtonOut>.Button3D{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.InvestDirectTop>a,.InvestDirect>.StandartIn>a{
    text-decoration: none;
}

.InvestDirectTop{
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 40px;

}

.InvestDirectTitle{
    font-size: 30px;
    color: #075a89;
    font-weight: 500;
}
.InvestDirectText{
    font-size: 22px;
    color: #413422;
}

.InvestDirect00{
    padding: 0 20px 35px 10px;
}

.InvestDirectIMG00{
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
}

.InvestDirectH2{
    font-size: 24px;
    color: #3f3324;
    font-weight: 500;
}

.InvestDirect01{
    border-left: 17px solid #988aff;
    padding-left: 17px;
}
.InvestDirect02{
    border-left: 5px solid #97249e;
    padding: 20px 31px 20px 31px ;
    font-size: 20px;
    color: #3d3226;
    margin-bottom: 35px;
}

.InvestDirectCenterDiv>div{
    padding-left: 35px;
    padding-right: 35px;
}

.InvestDirectCenterDiv>.InvestDirectCenterDiv{
    margin-top: 10px;
    margin-bottom: 30px;
}
.InvestDirectCenterDiv>.InvestDirectCenterDiv>a{
    color: #0069d4;
}

.InvestDirect03{
    padding-bottom: 32px;
}

.InvestDirectSmallGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
    margin-bottom: 55px;
    justify-content: center;
}

.InvestDirectSmallGridElement{
    width: 296px;
    margin: auto;
}

.InvestDirectSmallGridElement>img{
    margin-bottom: 14px;
}


.InvestDirectSmallGridElement>div{
    font-size: 16px;
}

.InvestDirectCenterDiv>.InvestDirectText{
    font-size: 16px;
}

.InvestDirectText>a{
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    color: #0069d4;
}

.InvestDirectMainTitle{
    text-align: center;
    font-size: 40px;
    color: #075a89;
    margin-bottom: 20px;
    font-weight: 300;
}
.InvestDirectMainH1{
    text-align: center;
    font-size: 30px;
    color: #303030;
    margin-bottom: 20px;
}

.InvestDirectMainGrid{
    display: grid;
    grid-template-columns: 74px auto;
}

.InvestDirectMainGridWithRound{
    height: 45px;
    align-items: center;
}

.InvestDirectMainGridNoRound{
    margin-top: 10px;
}

.InvestDirectMainGridLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
}

.InvestDirectMainGridLeft>img{
    position: relative;
}

.InvestDirectMainGridRight{
    /*padding-top: 15px;*/
}

.InvestDirectMainGridLeftRound01{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(7, 90, 137,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 700;
}

.InvestDirectMainGridLeftRound02{
    /*margin-top: 5px;*/
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(7, 90, 137);
    /*background-color: #075a89;*/
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 700;
}
.InvestDirectMainGridLeftRound02Special{
    background-color: rgba(7, 90, 137, 0.7);
}

.InvestDirectMainGridNoRound>.InvestDirectMainGridRight>img{
    margin-left: -16px;
}
.InvestDirectMainGridRight>.InvestDirectEx03Out>img{
    margin-left: -16px;
}
.InvestDirectEx07GridElement>img{
    margin-left: -16px;
}

.InvestDirectEx01{
    width: 264px;
    margin-bottom: 30px;
}
.InvestDirectEx03{
    width: 462px;
}

.InvestDirectLine{
    height: 88px;
}

.InvestDirectMainGridH2{
    font-size: 20px;
    font-weight: 500;
    color: #3d3226;
    /*margin-bottom: 25px;*/
}
.InvestDirectMainGridText{
    font-size: 16px;
    color: #3d3226;
    margin-bottom: 20px;
}

.InvestDirectEx0101{
    font-size: 14px;
    font-weight: 700;
    color: rgba(92,155,36,0.9);
    margin-left: 110px;
    margin-top: 5px;
}

.InvestDirectEx0102{
    font-size: 14px;
    font-weight: 500;
    color: #454545;
    margin-left: 20px;
}

.InvestDirectEx03Out{
    display: flex;
    align-items: center;
}

.InvestDirectMainGridOut{
    border: solid 1px #075a89;
    padding: 30px 50px 50px 40px;
    border-radius: 10px;
    background-color: #f2f2f2;
}
.InvestDirectEx04{
    width: 361px;
}
.InvestDirectEx05{
    width: 361px;
}
.InvestDirectEx06{
    width: 361px;
}
.InvestDirectEx07{
    width: 303px;
}

.InvestDirectMain05{
    margin-top: 50px;
    margin-bottom: 20px;
}

.InvestDirectEx04Grid{
    display: grid;
    width: 360px;
    grid-template-columns: 180px 180px;
    justify-content: center;
    text-align: center;
    margin-bottom: 15px;
}

.InvestDirectEx0103{
    font-size: 14px;
    font-weight: 500;
    color: rgba(139,28,147,0.9);
}
.InvestDirectEx0104{
    font-size: 14px;
    font-weight: 500;
    color: rgba(92,155,36,0.9);
}

.InvestDirectEx05Grid{
    /*margin-bottom: 5px;*/
}

.InvestDirectEx07Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.InvestDirectEx07GridElement{
    text-align: center;
}

.InvestDirectEx0701{
    font-size: 14px;
    font-weight: 500;
    color: rgba(139,28,147,0.9);
}
.InvestDirectEx0702{
    font-size: 14px;
    font-weight: 500;
    color: rgba(92,155,36,0.9);
}

.InvestDirectTextBottom{
    padding: 30px 24px 24px 24px ;
    font-size: 18px;
    color: #3f3324;
}

.InvestDirectTextBottom>b{
    padding-right: 5px;
    padding-left: 5px;
}

.InvestDirectBottomButton{
    margin-bottom: 35px;
    margin-left: 25px;
}

@media only screen and (max-width: 800px) {
    .InvestDirect>.StandartIn{
        padding: unset;
    }
    .InvestDirectTop{
        padding-left: 11px;
        padding-right: 11px;
    }
    .InvestDirectMainGridOut{
        border-left: none;
        border-right: none;
        border-radius: unset;
    }
    .InvestDirectEx06{
        width: auto;
        height: 50px;
    }
    .InvestDirectEx07{
        width: auto;
        height: 50px;
    }
}

@media only screen and (max-width: 720px) {
    .InvestDirectEx03Out{
        position: relative;
    }
    .InvestDirectEx0102{
        position: absolute;
        width: 100px;
        left: 350px;
        bottom: -50px;
        text-align: right;
    }
}

@media only screen and (max-width: 700px) {
    .InvestDirectEx07Grid{
        display: block;
    }
    .InvestDirectEx07GridElement{
        display: flex;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        width: auto;
    }
    .InvestDirectEx07GridElement:first-of-type{
        margin-bottom: 20px;
    }
    .InvestDirectEx0701{
        width: 296px;
        text-align: center;
        padding-top: 10px;
    }
    .InvestDirectEx0702{
        width: 256px;
        text-align: center;
        padding-top: 10px;
    }

    }

@media only screen and (max-width: 650px) {
    .InvestDirectSmallGrid{
        display: block;
    }
    .InvestDirectSmallGridElement:first-of-type{
        margin-bottom: 20px;
    }
    .InvestDirectMainH1{
        font-size: 18px;
    }
    .InvestDirectMainGridH2{
        font-size: 18px;
    }
    .InvestDirectEx03Out>img{
        width: 100%;
    }
    .InvestDirectEx0102{
        left: auto;
        right: 10px;
    }
    .InvestDirectEx0101{
        margin-left: 10px;
    }
    .InvestDirectTop{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 520px) {
    .InvestDirectMainGridOut{
        padding-right: 5px;
        padding-left: 5px;
    }
}
@media only screen and (max-width: 480px) {
    .InvestDirectMainGrid{
        grid-template-columns: 55px minmax(255px,430px);
    }
}

@media only screen and (max-width: 440px) {
    .InvestDirectMainGridRight{
        width: 100%;
    }
    .InvestDirectEx01{
        width: 100%;
    }
    .InvestDirectEx05{
        width: 100%;
    }
    .InvestDirectEx04{
        width: 100%;
    }
    .InvestDirectEx06{
        width: 361px;
    }
    .InvestDirectEx07{
        width: 361px;
    }
    .InvestDirectEx04Grid{
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 400px) {
    .InvestDirectEx03Out,.InvestDirectEx07GridElement{
        position: relative;
        left: -40px;
    }
    .InvestDirectCenterDiv>div{
        padding-left: 5px;
    }

    .InvestDirectEx0701{
        font-size: 12px;
    }
}




