.AdvantagesPage{
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.AdvantagesTitle{
    font-size: 24px;
    font-weight: 500;
    color: #075a89;
}

.AdvantagesH1{
    font-size: 31px;
    font-weight: 400;
    padding-top: 50px;
    color: #262626;
    text-align: center;
}

.AdvantagesTop{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 50px;
}
.AdvantagesTopTerminals{
    display: flex;
    justify-content: space-around;
}

.AdvantagesTopECN{
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
}

.AdvantagesTopECN>img{
    justify-self: center;
}


.AdvantagesTopECNBottomElements{
    display: flex;
}

.AdvantagesTopECNBottomTitle{
    font-size: 16px;
    font-weight: 500;
    padding-top: 20px;
    color: #005b85;
    text-align: center;
}

.AdvantagesTopTerminalsElement{
    text-align: center;
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
    grid-template-rows: 80px auto;
}
.AdvantagesTopTerminalsElement>img{
    margin: auto;
}

.AdvantagesTopTerminalsElementText{
    color: #262626;
    font-size: 18px;
    font-weight: 500;
}

/*.AdvantagesTopECNBottomElementIn:first-of-type>.AdvantagesTopECNBottomElementInTitle,.AdvantagesTopECNBottomElementIn:first-of-type>.AdvantagesTopECNBottomElementInText,*/
/*.AdvantagesTopECNBottomElementIn:nth-of-type(2)>.AdvantagesTopECNBottomElementInTitle,.AdvantagesTopECNBottomElementIn:nth-of-type(2)>.AdvantagesTopECNBottomElementInText*/

.AdvantagesTopECNBottomTitle{
    margin-bottom: 15px;
}
.AdvantagesTopECNBottomElementIn:first-of-type
{
    border-right: 3px solid #d9d9d9;
}.AdvantagesTopECNBottomElementIn:last-of-type
{
    border-left: 3px solid #d9d9d9;
}

.AdvantagesTopECNBottomElementInTitle{
    font-size: 16px;
    font-weight: 500;
    /*margin-top: 20px;*/
    padding-top: 10px;
    color: #005b85;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

}
.AdvantagesTopECNBottomElementInText{
    font-size: 16px;
    font-weight: 400;
    color: #484848;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.AdvantagesBonus{
    margin-top: 40px;
    width: 100%;
    height: auto;
    background-image: url("../../../static/img/Advantages/Advantages10.png");
    background-position: center;
    padding-bottom: 30px;
}


.AdvantagesBonusTitle{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding-top: 33px;
}
.AdvantagesPage01Line:first-of-type{
    justify-self: end;
}


.AdvantagesPage01Line{
    height: 3px;
    width: 132px;
    /*background-color: #d9e7ee;*/
    background-color: #4683a7;
}


.AdvantagesPage01Text{
    color: white;
    font-size: 30px;
    padding-left: 21px;
    padding-right: 21px;
    text-align: center;
}

.AdvantagesBonusGrid{
    display: grid;
    grid-template-columns: 1fr 305px 1fr;
    font-size: 20px;
    color: white;
    grid-row-gap: 50px;

}

.AdvantagesBonusGridOut{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
}

.AdvantagesBonusGridElement{
    display: grid;
    grid-template-columns: auto 46px;
    grid-column-gap: 30px;
    align-items: center;
}
.AdvantagesBonusGridElementLeft{
    display: grid;
    grid-template-columns: 46px auto;
    grid-column-gap: 30px;
    align-items: center;
}

.AdvantagesBonusGridElementImg{
    grid-column: 2;
    grid-row: 1;
}
.AdvantagesBonusGridElementText{
    grid-column: 1;
    grid-row: 1;
}

.AdvantagesBonusGrid02{
    grid-column: 1;
    grid-row: 2;
}
.AdvantagesBonusGrid03{
    grid-column: 3;
    grid-row: 2;
}
.AdvantagesBonusGrid04{
    grid-column: 3;
    grid-row: 1;
}

.Advantages02{
    margin-top: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.Advantages02LeftTop{
    display: flex;
    justify-content: space-around;
}

.Advantages02LeftTopText{
    text-align: center;
}

.Advantages02LeftTopElement{
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    grid-template-rows: 80px;
    grid-row-gap: 25px;
    color: #005b85;
    font-size: 16px;
    font-weight: 700;
}

.Advantages02LeftText{
    text-align: center;
    margin-top: 25px;
    font-size: 19px;
    color: #484848;
}

.Advantages02RightElement{
    display: grid;
    grid-template-columns: 45px auto;
    grid-column-gap: 36px;
    margin-bottom: 24px;
    align-items: center;
    font-size: 19px;
    color: #484848;
}

.AdvantagesConfidence{
    margin-top: 40px;
    width: 100%;
    height: auto;
    background-image: url("../../../static/img/Advantages/Advantages11.svg");
    background-position: center;
    padding-bottom: 30px;
}


.AdvantagesConfidenceTitle{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding-top: 33px;
}
.AdvantagesPage01Line:first-of-type{
    justify-self: end;
}


.AdvantagesPage01Line{
    height: 3px;
    width: 132px;
    /*background-color: #d9e7ee;*/
    background-color: #4683a7;
}


.AdvantagesPage01Text{
    color: white;
    font-size: 30px;
    padding-left: 21px;
    padding-right: 21px;
}

.AdvantagesConfidenceGrid{
    display: grid;
    grid-template-columns: 1fr 295px 1fr;
    font-size: 20px;
    color: white;
    grid-row-gap: 20px;
}

.AdvantagesConfidenceGridOut{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
}

.AdvantagesConfidenceGridElement{
    display: grid;
    grid-template-columns: auto 46px;
    grid-column-gap: 30px;
    align-items: center;
}
.AdvantagesConfidenceGridElementLeft{
    display: grid;
    grid-template-columns: 46px auto;
    grid-column-gap: 30px;
    align-items: center;
}

.AdvantagesConfidenceGridElementImg{
    grid-column: 2;
    grid-row: 1;
}

.AdvantagesConfidenceGridElementImgLeft{
    display: flex;
    align-items: center;
}
.AdvantagesConfidenceGridElementText{
    grid-column: 1;
    grid-row: 1;
}

.AdvantagesConfidenceGrid02{
    grid-column: 1;
    grid-row: 2;
}
.AdvantagesConfidenceGrid03{
    grid-column: 1;
    grid-row: 3;
}
.AdvantagesConfidenceGrid04{
    grid-column: 3;
    grid-row: 1;
}
.AdvantagesConfidenceGrid05{
    grid-column: 3;
    grid-row: 2;
}
.AdvantagesConfidenceGrid06{
    grid-column: 3;
    grid-row: 3;
}

.Advantages03{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}

.Advantages03>div{
    margin-bottom: 25px;
}
.Advantages03Element01:first-of-type>.Advantages03Element01Top{
    width: 83px;
}
.Advantages03Element01Top,.Advantages03Element02Bottom,.Advantages03Element04Bottom,.Advantages03Element05Top{
    font-size: 60px;
    display: flex;
    color: #fd9a0f;
    /*width: 93px;*/
    justify-content: space-between;
}

.AdvantagesBonusGridOut>img{
    display: none;
}

.Advantages03Element03Top{
    color: #fd9a0f;
    font-size: 38px;
}

.Advantages03ElementHalfBig,.Advantages03ElementHalfBig{
    font-size: 28px;
    align-self: end;
    margin-left: 12px;
    margin-top: 8px;
}

.Advantages03Element01Bottom,.Advantages03Element02Top,.Advantages03Element03Bottom,.Advantages03Element04Top,.Advantages03Element05Bottom{
    font-size: 20px;
    color: #4e4848;
}

.Advantages03{
    margin-top: 20px;
}
.Advantages03>div{
    margin-left: 20px;
    margin-right: 20px;
}



@media only screen and (max-width: 950px) {
    .AdvantagesBonusGridElement,.AdvantagesBonusGridElementLeft{
        grid-template-columns: auto;
        justify-content: center;
    }
    .AdvantagesBonusGridElementImg,.AdvantagesBonusGridElementImgLeft{
        grid-column: 1;
        margin: auto;
    }
    .AdvantagesBonusGridElementText,.AdvantagesBonusGridElementTextLeft{
        grid-column: 1;
        grid-row: 2;
        text-align: center;
    }

    .AdvantagesTitle{
        text-align: center;
        font-size: 36px;
    }
    .AdvantagesH1{
        padding-right: 20px;
        padding-left: 20px;
    }
    .AdvantagesTopTerminals{
        display: flex;
        flex-wrap: wrap;
    }
    .AdvantagesTopTerminalsElement{
        margin-bottom: 30px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .AdvantagesTop{
        grid-template-columns: 100%;
    }
    .AdvantagesTopECN{
        margin-top: 50px;
    }
    .AdvantagesTopECNBottomElementInText{
        padding-right: 20px;
        padding-left: 20px;
    }
    .AdvantagesTopECNBottom,.AdvantagesTopECN{
        width: 100%;
        grid-template-columns: 100%;
    }
    .AdvantagesTopECNBottomElements{
        /*width: 80%;*/
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}



@media only screen and (max-width: 850px) {
    .AdvantagesBonusGrid{
        grid-template-columns: 1fr 250px 1fr;
    }
}
@media only screen and (max-width: 700px) {
    .AdvantagesBonusGrid{
        grid-template-columns: 1fr 150px 1fr;
    }
}

@media only screen and (max-width: 650px) {
    .AdvantagesBonus{
        background-image: url("../../../static/img/Advantages/Advantages11.svg");
        background-position: center;
        background-size: cover;
    }
}
@media only screen and (max-width: 550px) {
    .AdvantagesPage01Line{
        height: 3px;
        width: 26px;
        /*background-color: #d9e7ee;*/
        background-color: #4683a7;
    }
}



@media only screen and (max-width: 500px) {
    .AdvantagesBonusGridOut{
        margin-left: 0;
        margin-right: 0;
    }
    .AdvantagesBonusGridElementImg{
        grid-column: 2;
        grid-row: 1;
        margin-left: 0;
    }
    .AdvantagesBonusGridElementText{
        grid-column: 1;
        grid-row: 1;
    }

    .AdvantagesBonusGridElementLeft {
        grid-template-columns: 46px auto;
    }
    .AdvantagesBonusGridElementImgLeft {
        grid-column: 1;
        grid-row: 1;
    }
    .AdvantagesBonusGridElementTextLeft {
        grid-column: 2;
        grid-row: 1;
        text-align: center;
    }
    .AdvantagesTopECNBottomElementInTitle{
        margin-top: 0;
    }
    .AdvantagesTopECNBottomElements{
        display: grid;
        grid-template-columns: 1fr 1fr;
        /*align-items: center;*/
    }
    .AdvantagesTopECNBottomElementIn{
        margin-top: 20px;
    }
    .AdvantagesTopECNBottomTitle{
        margin-bottom: 20px;
    }
    .AdvantagesTopECNBottomElementIn:first-of-type>.AdvantagesTopECNBottomElementInTitle{
        min-height: 30px;

    }
    .AdvantagesTopECNBottomElementIn:nth-of-type(2){
        grid-column: 1/span 2;
        grid-row: 1;
        max-width: 200px;
        border-right: 3px solid #d9d9d9;
        border-left: 3px solid #d9d9d9;
    }
    .AdvantagesTopECNBottomElementIn:nth-of-type(2){
        margin: auto;
    }
    .AdvantagesTopECNBottomElements>.AdvantagesTopECNBottomElementIn>.AdvantagesTopECNBottomElementInTitle,.AdvantagesTopECNBottomElements>.AdvantagesTopECNBottomElementIn>.AdvantagesTopECNBottomElementInText{
        border-right: none;
    }
    .AdvantagesTopECNBottomElementIn:nth-of-type(1){
        grid-column: 1;
        grid-row: 2;
        border-right: none;
    }
    .AdvantagesTopECNBottomElementIn:nth-of-type(3){
        grid-column: 2;
        grid-row: 2;
    }
    .AdvantagesBonusTitle{
        width: 100%;
        grid-template-columns: 20px  auto  20px;
    }
    .AdvantagesPage01Text{
        margin: auto;
    }
    .AdvantagesBonus{
        background-image: url("../../../static/img/Advantages/Advantages11.svg");
        background-position: center;
        background-size: cover;
        padding-bottom: 0;
    }
    .AdvantagesBonusGridOut>img{
        /*margin: auto;*/
        width: 100%;
    }
    .AdvantagesBonusGrid{
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
    }
    .AdvantagesBonusGrid{
        grid-template-columns: auto;
    }

    .AdvantagesBonusGrid02{
        grid-column: 1;
        grid-row: 2;
    }
    .AdvantagesBonusGrid03{
        grid-column: 1;
        grid-row: 3;
    }
    .AdvantagesBonusGrid04{
        grid-column: 1;
        grid-row: 4;
    }
    .AdvantagesBonusGrid05{
        grid-column: 1;
        grid-row: 5;
    }
    .AdvantagesBonusGrid06{
        grid-column: 1;
        grid-row: 6;
    }
    .AdvantagesBonus{
        height: auto;
    }
    .AdvantagesBonusGridElementImg{
        grid-column: 1;
    }
    .AdvantagesBonusGridElementText{
        grid-column: 2;
    }
    .AdvantagesBonusGridElement{
        grid-template-columns: 46px auto;
    }

    .AdvantagesBonusGridOut>img{
        display: block;
    }
    .Advantages02{
        grid-template-columns: auto;
    }
    .Advantages02Right{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 850px) {

    .AdvantagesConfidence{
        height: auto;
    }
    .AdvantagesConfidenceGrid{
        grid-template-columns: auto;
    }

    .AdvantagesConfidenceGrid02{
        grid-column: 1;
        grid-row: 2;
    }
    .AdvantagesConfidenceGrid03{
        grid-column: 1;
        grid-row: 3;
    }
    .AdvantagesConfidenceGrid04{
        grid-column: 1;
        grid-row: 4;
    }
    .AdvantagesConfidenceGrid05{
        grid-column: 1;
        grid-row: 5;
    }
    .AdvantagesConfidenceGrid06{
        grid-column: 1;
        grid-row: 6;
    }
    .AdvantagesConfidenceGridElement{
        grid-template-columns: 46px auto;
    }
    .AdvantagesConfidenceGridElementImg{
        grid-column: 1;
    }
    .AdvantagesConfidenceGridElementText{
        grid-column: 2;
    }
}

@media only screen and (max-width: 450px) {
    .AdvantagesBonusGridOut>img{
        width: 100%;
    }
}

.AdvantagesConfidenceGrid{
    position: relative;
}

.AdvantagesConfidenceGridIMGSafe{
    position: absolute;
    top: calc(50% - 82px);
    left: calc(50% - 82px);
}

@media only screen and (max-width: 850px) {
    .AdvantagesConfidenceGridIMGSafe{
        position: absolute;
        top: calc(50% - 82px);
        left: auto;
        right: 20px;
    }
}
@media only screen and (max-width: 500px) {
    .AdvantagesBonusGridElement, .AdvantagesBonusGridElementLeft{
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 700px) {
    .AdvantagesConfidenceGridIMGSafe{
        display: none;
    }
    .AdvantagesBonusGridElementText{
        text-align: left;
    }
}
.Advantages04{
    margin-bottom: 26px;
}