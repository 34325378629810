@keyframes bannerOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.IndexBlocksOutOut{
    position: relative;
}

.IndexBlocksSelect{
    display: grid;
    grid-template-columns: 17px 17px 17px 17px 17px 17px;
    position: absolute;
    bottom: 0;
    right: 0;
    grid-column-gap: 13px;
}

.IndexBlocksSelectElement{
    width: 17px;
    height: 9px;
    background-color: gray;
    cursor: pointer;
}
.IndexBlocksSelectElementActive{
    width: 17px;
    height: 9px;
    background-color: #fcb23c;
}
