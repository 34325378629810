.Conditions{
    max-width: 1000px;
    margin: auto;
    padding-bottom: 15px;
}

@media only screen and (max-width: 1150px) {
    .ConditionsTitle{
    }
}

.ant-card-body>h2{
    font-size: 30px;
}