@keyframes bannerOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.BannerProtect{
    width: 100%;
    height: 294px;
    background: url("../../../static/img/Index/Banner03.png");
    position: relative;
    animation-name: bannerOpacity;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

.BannerProtectRight{
    width: 500px;
    margin-left: 35px;
    justify-self: right;
    grid-row-gap: 20px;
}

.BannerProtectTitle{
    font-size: 36px;
    font-weight: 300;
    line-height: 50px;
    color: white;
    padding-top: 40px;
    position: relative;
    left: 40px;
    top: 3px;
}

.BannerProtectText{
    font-size: 14px;
    line-height: 20px;
    padding-top: 20px;
}

.BannerProtectText>ul{
    list-style-type: none;
    padding-left: 0;
}

.BannerProtectText>ul>li:before{
    content: "•";
    color: white;
    margin-right: 30px;
}
.BannerProtectText>ul>li{
    margin-top: 10px;
    font-size: 14px;
    color: white;
}

.BannerProtectButton{
    position: absolute;
    left: 340px;
    top: 170px;
}


