.NewsTitle{
    margin-left: 11px;
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.AboutNewsThis{
    max-width: 1078px;
    padding-bottom: 15px;
    margin: auto auto;
}

@media only screen and (max-width: 1150px) {
    .AboutNews{
        margin: 10px;
    }
}

.AboutNewsTitleAbout{
    font-family: GothamPro, sans-serif;
    font-weight: 400;
    font-size: 38px;
    color: rgb(24,24,24);
    padding-bottom: 15px;
}

@media only screen and (max-width: 650px) {
    .AboutNewsTitleAbout{
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1150px) {
    .AboutNewsTitleAbout{
        padding-left: 22px;
    }
}

.AboutNewsYear{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
}


@media only screen and (max-width: 1150px) {
    .AboutNewsTitle,.AboutNewsYear{
        padding-left: 24px;
    }

}
@media only screen and (max-width: 650px) {
    .AboutNews{
        margin: 0;
    }
    .AboutNewsTitle, .AboutNewsYear{
        margin-left: 28px;
        padding-left: 0;
    }
}

.AboutNewsYearElement{
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 17px;
    /*color: rgb(10,121,191);*/
    color: #a6a7aa;
    padding: 5px 20px;
}
.AboutNewsYearElement:hover{
    color: #005b85;
}


.AboutNewsYearElementActive{
    border-radius: 27px;
    border-style: none;
    /*background-color: rgb(33,142,210);*/
    /*font-family: Roboto, sans-serif;*/
    font-weight: 400;
    font-size: 17px;
    /*color: white;*/
    color: #005b85;
}

.AboutNewsElement{
    background-color: white;
    padding: 30px;
    /*display: grid;*/
    /*grid-template-columns: 100px auto;*/

    margin-bottom: 10px;
}

@media only screen and (max-width: 650px) {
    .AboutNewsElement{
        grid-template-columns: auto;
        grid-template-rows: 45px auto;
    }
}

.AboutNewsElementDate{
    display: flex;
    /*display: grid;*/
    /*grid-template-columns: 36px 8px 30px;*/
    /*grid-template-rows: 10px 3px 10px;*/
    /*font-family: GothamPro, sans-serif;*/
    font-weight: 500;
    color: #ff9937;
    font-size: 13px;
}



.AboutNewsElementDateDate{
    /*grid-column: 1;*/
    /*grid-row: 1/4;*/
    /*font-weight: 500;*/
    /*font-size: 31px;*/
    /*position: relative;*/
    /*top: -4px;*/
}

.AboutNewsElementDateMonth{
    /*grid-column: 3;*/
    /*grid-row: 1;*/
    /*font-weight: 400;*/
    /*font-size: 13px;*/
    margin-right: 7px;
    margin-left: 7px;
}

.AboutNewsElementDateYear{
    /*grid-column: 3;*/
    /*grid-row: 3;*/
    /*font-weight: 400;*/
    /*font-size: 13px;*/
    margin-right: 10px;
}

.AboutNewsElementNews{
    color: rgb(24,24,24);
}

.AboutNewsElementNewsTitle{
    position: relative;
    top: -2px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    color: black;
}

.AboutNewsElementNewsText{
    color: #878787;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
}



@media only screen and (max-width: 450px) {
    .AboutNewsElementDate{
        display: grid;
        grid-template-columns: 20px 70px auto;
    }
    .AboutNewsElementNewsTitle{
        margin-top: 10px;
        grid-column: 1/span 3;
    }
    .AboutNewsElement{
        padding-bottom: 0;
        padding-top: 20px;
    }
}