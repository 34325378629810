.TradingHoursMain{
    display: grid;
    grid-template-columns: 725px 188px;
    justify-content: space-between;
}

.TradingHoursMainRightElement{
    display: grid;
    grid-template-columns: 30px 149px;
}

.TradingHoursMainRightElementLabel01{
    color: #00a3ff;
    font-size: 20px;
    margin-top: 3px;
}

.TradingHoursMainRightElementText{
    font-size: 11px;
    color: #484848;
    line-height: 16px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid gray;
}

.TradingHoursMainRightElementText:last-of-type{
    border-bottom: none;
}

.TradingHoursMainRightElementLabel02{
    width: 4px;
    height: 4px;
    background-color: #fd9a0f;
    margin-top: 5px;
}

.TradingHoursTitle{
    font-size: 18px;
    margin-bottom: 40px;
    font-weight: 500;
    padding-top: 30px;
}

.TradingHoursMainLeftTop{
    width: 100%;
    height: 42px;
    line-height: 42px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background-color: #075a89;
}
.TradingHoursMainLeftGray{
    width: 100%;
    height: 28px;
    line-height: 28px;
    color: #fdfeef;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    background-color: #c4c5c7;
}


.TradingHoursMainLeftInstrument:nth-of-type(2n)>.TradingHoursMainLeftInstrumentTitle{
    background-color: #f7f6fb;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n)>.TradingHoursMainLeftInstrumentInfo{
    background-color: #eff0f6;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n)>.TradingHoursMainLeftInstrumentStart{
    background-color: #f7f6fb;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n)>.TradingHoursMainLeftInstrumentStop{
    background-color: #eff0f6;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n+1)>.TradingHoursMainLeftInstrumentTitle{
    background-color: white;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n+1)>.TradingHoursMainLeftInstrumentInfo{
    background-color: #f6f9fa;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n+1)>.TradingHoursMainLeftInstrumentStart{
    background-color: white;
}
.TradingHoursMainLeftInstrument:nth-of-type(2n+1)>.TradingHoursMainLeftInstrumentStop{
    background-color: #f6f9fa;
}

.TradingHoursMainLeftInstrumentTitle{
    color: #0582c9;
    padding-left: 15px;
    font-weight: 500;
}
.TradingHoursMainLeftInstrumentInfo{
    color: #3a6072;
    padding-left: 15px;
    font-weight: 500;
}
.TradingHoursMainLeftInstrumentStart,.TradingHoursMainLeftInstrumentStop{
    color: #4b4e56;
    padding-left: 15px;
}

.TradingHoursMainLeftInstrument{
    width: 100%;
    /*height: 32px;*/
    line-height: 32px;
    font-size: 12px;
    text-align: left;
}

.TradingHoursMainLeftGray,.TradingHoursMainLeftInstrument{
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 2fr;
}


@media only screen and (max-width: 980px) {
    .TradingHoursMain{
        display: block;
    }
    .TradingHoursMainRight{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 700px) {
    .TradingHoursMainLeftGray,.TradingHoursMainLeftInstrument{
        grid-template-columns: 1fr 1fr;
        height: auto;
    }
    .TradingHoursMainLeftGrayElement{
        height: auto;
        line-height: 21px;
    }
    .TradingHoursMainLeftInstrument>div{
        height: auto;
        line-height: 21px;
    }
    .TradingHoursMainLeftInstrumentInfo{
        grid-column: 1;
        grid-row: 2;
    }
    .TradingHoursMainLeftInstrumentStart{
        grid-column: 2;
        grid-row: 1;
    }
    .TradingHoursMainLeftInstrumentStop{
        grid-column: 2;
        grid-row: 2;
    }
}
