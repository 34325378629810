.FooterAll{
    width: 100%;
}


.FooterAllTop{
    padding: 20px 20px 5px 30px;
    background-color: #f5f5f5;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 31px 280px auto auto;
    grid-column-gap: 24px;
}


.FooterAllTopElementAward{
    grid-row: 1/span 2;
    grid-column: 4;
    display: flex;
    align-items: center;
}
.FooterAllTopElementHREF{
    grid-row: 1/span 2;
    grid-column: 3;
}
.FooterAllTopElementGurs{
    display: flex;
}

.FooterAllTopElementCopyright{
    grid-column: 1/span 2;
    grid-row: 2;
    border-top: 1px solid rgba(48,48,48,0.2);
    text-align: center;
    padding-top: 8px;
    font-size: 12px;
    color: #33363f;
    font-weight: 700;
}

.FooterAllTopElementText{
    grid-column: 1/span 4;
    margin-top: 15px;
    margin-bottom: 15px;
    line-height: 20px;
}

.footer_gursIMG{
    margin-right: 13px;
}
.footer_gursText{
    color: #7b7b7b;
    font-size: 13px;
    line-height: 15px;
}
.footer_gursTitle{
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 15px;
}

.FooterAllLeft{
    max-width: 620px;
}

.FooterAllLeftBottom{
    margin-top: 8px;
    display: flex;
}
.FooterAllLeftBottomHREF{
    display: flex;
    margin-right: 20px;
}

.FooterAllLeftBottomHREFElement>a,.FooterAllLeftBottomHREFElement>a:visited{
    color: #0a79bf;
    font-size: 12px;
    line-height: 24px;
}


.footer_gurs{
    display: flex;

}

.footer_gurs>div>a>img{
    position: relative;
    top: 2px;
    padding-right: 10px;
}

.FooterAllTopIn{
    padding: 24px 10px 10px 24px;
    display: grid;
    grid-template-columns: auto 250px;
}

.FooterAllLeftText{
    font-size: 11px;
    font-weight: 500;
    color: #33363f;
}


.FooterAllRight{
    display: flex;
}

.FooterAllRightAward{
    width: 93px;
    height: 91px;
    /**/
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 15px;
    text-align: center;
    background: url("../../../static/img/Footer/BackPrize.png");
}

.FooterAllRightAwardText{
    padding-right: 14px;
    padding-left: 14px;

}
.FooterAllRightText{
    display: flex;
    align-items: center;
    font-size: 14px;
}


@media only screen and (max-width: 700px) {
    .FooterAllTopIn{
        display: block;
    }
    .FooterAllRight{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 570px) {
    .FooterAllLeftBottom{
        display: block;
    }
    .FooterAllLeftBottomCopyright{
        margin-top: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .FooterAllTop{
        grid-template-columns: 31px 280px auto;
    }

    .FooterAllTopElementAward{
        grid-column: 3;
        justify-self: center;
    }
    .FooterAllTopElementHREF{
        grid-row: 3;
        grid-column: 1/span 3;
        display: flex;
        justify-content: space-around;
        width: 100%;
        border-top: 1px solid rgba(48,48,48,0.2);
        background-color: #e5e5e5;
        padding-top: 11px;
        padding-bottom: 11px;
        margin-top: 20px;
    }
    .FooterAllTopElementText{
        grid-column: 1/span 3;
    }
}
@media only screen and (max-width: 650px) {
    .FooterAllTopElementTwitter{
        grid-column: 1;
        grid-row: 2;
        display: flex;
        justify-content: center;
    }
    .FooterAllRightAward{
        background-size: 63px 61px;
        background-repeat: no-repeat;
        background-position: center;

    }
    .FooterAllTopElementGurs{
        grid-row: 1;
        grid-column: 1/span 2;
        display: flex;
        justify-content: center;
    }
    .FooterAllTop{
        grid-template-columns: auto auto;
    }

    .FooterAllTopElementAward{
        grid-column: 2;
        grid-row: 2;
        margin-top: 10px;
    }
    .FooterAllTopElementHREF{
        grid-row: 4;
        grid-column: 1/span 2;
    }
    .FooterAllTopElementText{
        grid-row: 5;
        grid-column: 1/span 2;
    }
    .FooterAllTopElementCopyright{
        grid-row: 6;
        grid-column: 1/span 2;
    }
}
@media only screen and (max-width: 550px) {
    .FooterAllTop{
        padding-left: 0;
        padding-right: 0;
    }
    .FooterAllTopElementHREF>div{
        margin-left: 20px;
    }
    .FooterAllTopElementHREF{
        display: grid;
        grid-template-columns: auto auto;
    }
    .FooterAllTopElementText{
        padding-left: 15px;
        padding-right: 15px;
    }
}
