.IfYouHaveQuestions{
    display: flex;
    align-items: center;
}

.IfYouHaveQuestionsText{
    padding-left: 10px;
    font-size: 16px;
    line-height: 15px;
    color: black;
}


.IfYouHaveQuestionsTextLink{

    cursor: pointer;
    font-weight: 800;
}

.IfYouHaveQuestionsTextLinkText{
    color: #218ed2;
    text-decoration: underline;
}

.IfYouHaveQuestionsActive{
    position: fixed;
    z-index: 1000;
    /*top: 220px;*/
    top: calc(50% - 280px );

    right: calc(50% - 571px );
    width: 412px;
    border: 1px solid #8d8d8d;
    background-color: #f6f6f6;
}

@media only screen and (max-width: 1025px) {
    .IfYouHaveQuestionsText{
        padding-right: 15px;
    }
}
@media only screen and (max-width: 1200px) {
    .IfYouHaveQuestionsActive{
        right: calc(50% - 546px );
    }
}
@media only screen and (max-width: 1157px) {
    .IfYouHaveQuestionsActive{
        right: 25px;
    }
}

.IfYouHaveQuestionsAllBlack{
    position: fixed;
    z-index: 900;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
}

.IfYouHaveQuestionsActiveIn{
    padding: 43px 37px 37px 37px;
}

.IfYouHaveQuestionsTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.IfYouHaveQuestionsTopTitle{
    font-size: 24px;
    font-weight: 500;
    color: #075a89;
    padding-bottom: 20px;
    border-bottom: 3px solid #d8d8d8;
    text-align: center;
}

.TextPlaceHolder{
    font-size: 13px;
    font-weight: 700;
    color: black;
}

.PersonalPageInputText{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
}
/*.PersonalPageInputText:nth-of-type(2){*/
/*    margin-top: 15px;*/
/*}*/

.PersonalPageInputError>label>.IfYouHaveQuestionsActiveInputText,.PersonalPageInputError>label>.PersonalPageInputText>.IfYouHaveQuestionsActiveTextareaText{
    color: #c93838;
}
.PersonalPageInputError>label>.PersonalPageInputErrorText::before,.PersonalPageInputError>label>.PersonalPageInputErrorText::before{
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fb7b7b;
    border-radius: 8px;
    content: "";
    left: -25px;
    top: -4px;
}
.PersonalPageInputError>label>.IfYouHaveQuestionsActiveInput,.PersonalPageInputError>label>.IfYouHaveQuestionsTextarea{
    border: 1px solid red;
}
.PersonalPageInputError>label>.PersonalPageInputErrorText,.PersonalPageInputError>label>.PersonalPageInputText>.PersonalPageInputErrorText{
    display: flex;
    margin-left: 28px;
    margin-top: 5px;
    position: relative;
}

.PersonalPageInputErrorText{
    align-items: center;
    color: #c53838;
    font-size: 11px;
    display: none;
}

.IfYouHaveQuestionsActiveInput{
    width: calc(100% - 15px);
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
    padding-left: 15px;
    padding-bottom: 15px;
    border-radius: 3px;
}

.IfYouHaveQuestionsTextarea{
    height: 165px;
    width: calc(100% - 15px);
    font-size: 20px;
    font-weight: 500;
    padding-top: 30px;
    padding-left: 15px;
    padding-bottom: 15px;
    border-radius: 3px;
}

.TraderFeedbackSuccess{
    display: flex;
    margin-top: 21px;
    align-items: center;
}
.TraderFeedbackSuccessTitle{
    color: #3b3b3b;
    font-size: 24px;
    font-weight: 500;
    margin-left: 15px;
}

/*.TraderFeedbackSuccess>img{*/
/*    margin-top: 60px;*/
/*    margin-bottom: 32px;*/
/*}*/

.IfYouHaveQuestionsActiveIn>.StandartNotVisible{
    display: none;
}

.IfYouHaveQuestionsTopExit{
    cursor: pointer;
    height: 16px;
    font-size: initial;
    position: relative;
    color: #515456;
    text-decoration: underline;
}

.IfYouHaveQuestionsTopExit>img{
    margin-left: 10px;
    position: relative;
    top: 2px;
}

@media only screen and (max-width: 470px) {
    .IfYouHaveQuestionsActive{
        right: calc(50% - 210px);
    }
}
@media only screen and (max-width: 400px) {
    .IfYouHaveQuestionsActive{
        right: 0;
        /*width: calc(100% - 20px);*/
    }
    .IfYouHaveQuestionsActiveIn{
        width: auto;
    }
}

@media only screen and (max-width: 400px) {
    .IfYouHaveQuestionsActive{
        width: 100%;
    }
}
.IfYouHaveQuestionsButtonOut{
    border: none;
}

.IfYouHaveQuestionsBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 34px;

}

.IfYouHaveQuestionsBottom>button{
    padding: 0;
}

.IfYouHaveQuestionsActiveInputText{
    color: #a0a4b7;
    font-size: 16px;
    position: relative;
    font-weight: 800;
    top: 38px;
    left: 15px;
}

.IfYouHaveQuestionsActiveTextareaText{
    color: #a0a4b7;
    font-size: 16px;
    position: absolute;
    font-weight: 800;
    top: 18px;
    left: 15px;
}