@keyframes bannerOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.Banner500{
    width: 100%;
    height: 294px;
    background: url("../../../static/img/Index/Banner01.png");
    display: flex;
    justify-content: flex-end;
    animation-name: bannerOpacity;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

.Banner500Right{
    width: 350px;
    margin-right: 30px;
    color: #e5e5e5;
    justify-self: right;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-row-gap: 20px;
    margin-top: 22px;
}

.Banner500Title{
    font-size: 36px;
    font-weight: 300;
    line-height: 50px;
}
.Banner500Text{
    font-size: 14px;
    line-height: 20px;
}

@media only screen and (max-width: 920px) {
    .Banner500Right{
        margin-right: 5px;
    }
    .Banner500{
        background-position-x: -50px;
    }
    }

@media only screen and (max-width: 830px) {
    .Banner500Right{
        width: 250px;
        grid-row-gap: 0;
    }
    .Banner500Title{
        line-height: 36px;
    }
    }

